$campaign-delivery-overlay-component-name: 'campaign-delivery-overlay';
$lift-tooltip-content-component-name: 'lift-tooltip-content';

$campaign-delivery-overlay-component: get-component-uuid(
  $campaign-delivery-overlay-component-name
);
$lift-tooltip-content-component: get-component-uuid(
  $lift-tooltip-content-component-name
);

.#{$campaign-delivery-overlay-component} {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: solid 2px $color-yellow-dark;

  background-image: url($campaign_delivery_outcomes-chart-overlay---background-image);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.#{$lift-tooltip-content-component} {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 12px;
  }
}
