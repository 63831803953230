$icon-component-name: 'icon';

$icon-component: get-component-uuid($icon-component-name);

.#{$icon-component} {
  width: $icon---size;
  height: $icon---size;
  background-size: $icon---size $icon---size;
  background-repeat: no-repeat;
  display: inline-block;
  filter: $icon--gray---filter;

  &.gray {
    filter: $icon--gray---filter;
  }

  &.gray200 {
    filter: $icon--gray-200---filter;
  }

  &.orange {
    filter: $icon--orange---filter;
  }

  &.red {
    filter: $icon--red---filter;
  }

  &.dark {
    filter: $icon--dark---filter;
  }

  &.light {
    filter: $icon--light---filter;
  }

  &.primary {
    filter: $icon--gray---filter;
  }

  &.ultra-large {
    width: $icon--ultra-large---size;
    height: $icon--ultra-large---size;
    background-size: $icon--ultra-large---size $icon--ultra-large---size;
  }

  &.large {
    width: $icon--large---size;
    height: $icon--large---size;
    background-size: $icon--large---size $icon--large---size;
  }

  &.medium {
    width: $icon--medium---size;
    height: $icon--medium---size;
    background-size: $icon--medium---size $icon--medium---size;
  }

  &.small {
    width: $icon--small---size;
    height: $icon--small---size;
    background-size: $icon--small---size $icon--small---size;
  }

  &.micro {
    width: $icon--micro---size;
    height: $icon--micro---size;
    background-size: $icon--micro---size $icon--micro---size;
  }

  &.audit {
    background-image: url(#{$icon--audit---background-image});
  }

  &.rightArrow {
    background-image: url(#{$icon--right-arrow---background-image});
  }

  &.barChart {
    background-image: url(#{$icon--bar-chart---background-image});
  }

  &.checkmark {
    background-image: url(#{$icon--checkmark---background-image});
  }

  &.chevron {
    background-image: url(#{$icon--chevron---background-image});
  }

  &.close {
    background-image: url(#{$icon--close---background-image});
  }

  &.close2 {
    background-image: url(#{$icon--close2---background-image});
  }

  &.cog {
    background-image: url(#{$icon--cog---background-image});
  }

  &.copy {
    background-image: url(#{$icon--copy---background-image});
  }

  &.dashboard {
    background-image: url(#{$icon--dashboard---background-image});
  }

  &.cumulativeLegend {
    background-image: url(#{$icon--cumulative-legend---background-image});
  }

  &.database {
    background-image: url(#{$icon--database---background-image});
  }

  &.download {
    background-image: url(#{$icon--download---background-image});
  }

  &.edit {
    background-image: url(#{$icon--edit---background-image});
  }

  &.editInactive {
    background-image: url(#{$icon--edit-inactive---background-image});
  }

  &.folder {
    background-image: url(#{$icon--folder---background-image});
  }

  &.helpCircle {
    background-image: url(#{$icon--help-circle---background-image});
  }

  &.imp4ct {
    background-image: url(#{$icon--imp4ct---background-image});
  }

  &.info {
    background-image: url(#{$icon--info---background-image});
  }

  &.loading {
    background-image: url(#{$icon--loading---background-image});
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }

  &.logout {
    background-image: url(#{$icon--logout---background-image});
  }

  &.play {
    background-image: url(#{$icon--play---background-image});
  }

  &.plus {
    background-image: url(#{$icon--plus---background-image});
  }

  &.questionMark {
    background-image: url(#{$icon--question-mark---background-image});
  }

  &.save {
    background-image: url(#{$icon--save---background-image});
  }

  &.saveAs {
    background-image: url(#{$icon--save-as---background-image});
  }

  &.search {
    background-image: url(#{$icon--search---background-image});
  }

  &.settings {
    background-image: url(#{$icon--settings---background-image});
  }

  &.share {
    background-image: url(#{$icon--share---background-image});
  }

  &.standardLegend {
    background-image: url(#{$icon--standard-legend---background-image});
  }

  &.target {
    background-image: url(#{$icon--target---background-image});
  }

  &.trash {
    background-image: url(#{$icon--trash---background-image});
  }

  &.rectangle {
    background-image: url(#{$icon--rectangle---background-image});
  }

  &.rectangleFilled {
    background-image: url(#{$icon--rectangle-filled---background-image});
  }

  &.funnel {
    background-image: url(#{$icon--funnel---background-image});
  }

  &.funnel2 {
    background-image: url(#{$icon--funnel2---background-image});
  }

  &.money {
    background-image: url(#{$icon--money---background-image});
  }

  &.calendar2 {
    background-image: url(#{$icon--calendar2---background-image});
  }

  &.userRoles {
    background-image: url(#{$icon--user-roles---background-image});
  }

  &.user {
    background-image: url(#{$icon--user---background-image});
  }

  &.users {
    background-image: url(#{$icon--users---background-image});
  }

  &.chartTypeAreaDefault {
    background-image: url(#{$icon--chart-type-area-default---background-image});
  }

  &.chartTypeAreaSpline {
    background-image: url(#{$icon--chart-type-area-spline---background-image});
  }

  &.chartTypeAreaStackedPercentage {
    background-image: url(#{$icon--chart-type-area-stacked-percentage---background-image});
  }

  &.chartTypeAreaStacked {
    background-image: url(#{$icon--chart-type-area-stacked---background-image});
  }

  &.chartTypeBarStackedPercentage {
    background-image: url(#{$icon--chart-type-bar-stacked-percentage---background-image});
  }

  &.chartTypeBarStacked {
    background-image: url(#{$icon--chart-type-bar-stacked---background-image});
  }

  &.chartTypeBar {
    background-image: url(#{$icon--chart-type-bar---background-image});
  }

  &.chartTypeBubble {
    background-image: url(#{$icon--chart-type-bubble---background-image});
  }

  &.chartTypeColumnPercentageStacked {
    background-image: url(#{$icon--chart-type-column-percentage-stacked---background-image});
  }

  &.chartTypeColumnStacked {
    background-image: url(#{$icon--chart-type-column-stacked---background-image});
  }

  &.chartTypeColumn {
    background-image: url(#{$icon--chart-type-column---background-image});
  }

  &.chartTypeLineBold {
    background-image: url(#{$icon--chart-type-line-bold---background-image});
  }

  &.chartTypeLineSpline {
    background-image: url(#{$icon--chart-type-line-spline---background-image});
  }

  &.chartTypeLine {
    background-image: url(#{$icon--chart-type-line---background-image});
  }

  &.chartTypePie {
    background-image: url(#{$icon--chart-type-pie---background-image});
  }

  &.chartTypeSplineStackedPercentage {
    background-image: url(#{$icon--chart-type-spline-stacked-percentage---background-image});
  }

  &.chartTypeSplineStacked {
    background-image: url(#{$icon--chart-type-spline-stacked---background-image});
  }

  &.carrot {
    background-image: url(#{$icon--carrot---background-image});
  }

  &.link {
    background-image: url(#{$icon--link---background-image});
  }

  &.moreVertical {
    background-image: url(#{$icon--more-vertical---background-image});
  }

  &.public {
    background-image: url(#{$icon--public---background-image});
  }

  &.private {
    background-image: url(#{$icon--private---background-image});
  }

  &.calendar {
    background-image: url(#{$icon--calendar---background-image});
  }

  &.warning {
    background-image: url(#{$icon--warning---background-image});
    min-width: 20px;
  }

  &.door {
    background-image: url(#{$icon--door---background-image});
  }

  &.eye {
    background-image: url(#{$icon--eye---background-image});
  }

  &.genre {
    background-image: url(#{$icon--genre---background-image});
  }

  &.network {
    background-image: url(#{$icon--network---background-image});
  }

  &.houseMoney {
    background-image: url(#{$icon--house-money---background-image});
  }

  &.tv {
    background-image: url(#{$icon--tv---background-image});
  }

  &.creative {
    background-image: url(#{$icon--creative---background-image});
  }

  &.devices {
    background-image: url(#{$icon--devices---background-image});
  }

  &.daypart {
    background-image: url(#{$icon--daypart---background-image});
  }

  &.adDuration {
    background-image: url(#{$icon--ad-duration---background-image});
  }

  &.tvAd {
    background-image: url(#{$icon--tv-ad---background-image});
  }

  &.clickToScrollDown {
    background-image: url(#{$icon--click-to-scroll-down---background-image});
  }

  &.bubbleLegend {
    background-image: url(#{$icon--bubble-legend---background-image});
  }

  &.alertTriangle {
    background-image: url(#{$icon--alert-triangle---background-image});
  }

  &.multipleOptions {
    background-image: url(#{$icon--multiple-options---background-image});
  }

  &.wrench {
    background-image: url(#{$icon--wrench---background-image});
  }

  &.reportDetails {
    background-image: url(#{$icon--report-details---background-image});
  }

  &.boxArrowUpRight {
    background-image: url(#{$icon--box-arrow-up-right---background-image});
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
