@import './components/styles.scss';

$header-component-name: 'audience-demographic-header';

$header-component: get-component-uuid($header-component-name);

.#{$header-component} {
  display: flex;
  align-items: center;

  &-h2 {
    width: auto;
    color: $h2---color;
    font-family: $h2---font-family;
    font-size: $h2---font-size;
    padding: 0;
    margin: 0;
  }

  &-save-button {
    padding: 10px 20px;
    border-radius: 4px;
  }
}
