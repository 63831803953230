$role-form-component-name: 'role-form';

$role-form-component: get-component-uuid($role-form-component-name);
$checkbox-component: get-component-uuid($checkbox-component-name);
$flex-component: get-component-uuid($flex-component-name);
$label-component: get-component-uuid($label-component-name);

.#{$role-form-component} {
  width: 100%;

  &-search {
    padding: $table-search---padding;
    margin-bottom: $table-search---margin-bottom;
    width: 100%;
  }

  &-permission-subText {
    padding-left: 32px;
    color: $color-gray-700;
  }

  .#{$checkbox-component} {
    .#{$flex-component} {
      justify-content: flex-start;

      .#{$label-component} {
        text-transform: none;
      }
    }
  }
}
