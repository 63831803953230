$login-form---size: 500px;
$login-form-lg---size: 650px;
$login-form---border: 0.5px solid #d8d8d8;
$login-form---y-coord: 50%;
$login-form-border---length: 50%;
$login-form-border---content: ' ';
$login-form---margin-left: 90vw;
$login-form-logo---width: 50%;
$login-form-logo---margin-bottom: 30px;
$login-form-input---width: 200px;
$login-form-input---margin-bottom: 8px;
$login-form-input--last---margin-bottom: 0;
$login-form-button---margin-top: 25px;
$login-form-button-sm--margin-top: 14px;
$login-form-button-lg--width: 240px;
$login-form-error---margin-bottom: 0;
$login-form-button--error---margin-top: 19px;
