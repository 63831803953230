$progress-bar-component-name: 'progress-bar';

$progress-bar-component: get-component-uuid($progress-bar-component-name);

.#{$progress-bar-component} {
  &.loop {
    width: 100%;
    height: $progress-bar---height;
    background-image: $progress-bar-progress---background-image;
    background-size: 100% 400%;
    animation: pulse 0.5s linear 0s infinite;
  }

  &-wrapper {
    color: $progress-bar---color;
    width: 100%;
    display: flex;
    flex-direction: column;

    progress {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      height: $progress-bar---height;
      border: none;
    }

    progress::-webkit-progress-bar {
      background-color: $progress-bar---background;
    }

    progress::-webkit-progress-value {
      background-image: $progress-bar-progress---background-image;
    }

    progress::-moz-progress-bar {
      background-image: $progress-bar-progress---background-image;
    }
  }
}
