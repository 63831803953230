$segmented-control-component-name: 'segmented-control';

$segmented-control-component: get-component-uuid(
  $segmented-control-component-name
);
$button-component: get-component-uuid($button-component-name);
$icon-component: get-component-uuid($icon-component-name);

.#{$segmented-control-component} {
  width: 100%;

  .#{$button-component} {
    border-radius: 0;
    flex: 1;
    border-right: 0;
    white-space: nowrap;
    padding: 0;
    height: 40px;

    &:first-child {
      border-top-left-radius: $segmented-control---border-radius;
      border-bottom-left-radius: $segmented-control---border-radius;
      border-right: 0;
    }

    &:last-child {
      border-top-right-radius: $segmented-control---border-radius;
      border-bottom-right-radius: $segmented-control---border-radius;
      border-right: $segmented-control---border;

      &:hover {
        border-right: $segmented-control---border;
      }
    }

    &:hover {
      background: $segmented-control--hover---background;
      border-color: $segmented-control---border-color;
      border-right: 0;

      &.active {
        border-right: 0;
      }
    }

    &.active {
      border-color: $segmented-control---border-color;
      border-right: 0;
      background: $segmented-control--active---background;
      text-shadow: $segmented-control---active--text-shadow;

      &:last-child {
        border-right: $segmented-control---border;
      }
    }
  }

  &.small {
    font-family: $font-primary-400;
    font-weight: $font-weight-M;

    .#{$button-component} {
      padding: $segmented-control---padding--small;
      font-size: $font-size-S;
      height: $segmented-control---height--small;

      @media screen and (max-width: 1400px) {
        padding: $segmented-control---padding--very-small;

        .#{$icon-component} {
          &.medium {
            width: $icon--small---size;
            height: $icon--small---size;
            background-size: $icon--small---size $icon--small---size;
          }
        }
      }

      &:first-child {
        border-top-left-radius: $segmented-control---border-radius--small;
        border-bottom-left-radius: $segmented-control---border-radius--small;
      }

      &:last-child {
        border-top-right-radius: $segmented-control---border-radius--small;
        border-bottom-right-radius: $segmented-control---border-radius--small;
      }
    }
  }
}
