$order-by-component-name: 'order-by';

$accordion-component: get-component-uuid($order-by-component-name);
$dropdown-component: get-component-uuid($dropdown-component-name);

.#{$accordion-component} {
  label {
    margin-bottom: $field-label---margin-bottom;
    display: inline-block;
  }

  .#{$dropdown-component}:first-child {
    margin-right: $form--column---margin-right;
  }
}
