$form-component-name: 'client-form';
$form-component: get-component-uuid($form-component-name);

.#{$form-component} {
  &-uploadPath_copy {
    font-size: $font-size-M;
    color: $color-text-500;
  }
}

.r2d2-multiple-dropdown-select {
  width: 100%;
  .r2d2-multiple-dropdown-select-selector {
    width: 100%;
  }
}

.search-dropdown-container {
  .label-container {
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
}

.inline-form-fields {
  .inline-form-field-wrapper {
    width: 30%;
  }
}
