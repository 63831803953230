@import 'theme/default/mixins/utilities.scss';

$border-radii: (
  'top-left': 'tl',
  'top-right': 'tr',
  'bottom-right': 'br',
  'bottom-left': 'bl',
);
$border-widths: (
  'top-width': 'tw',
  'right-width': 'rw',
  'bottom-width': 'bw',
  'left-width': 'lw',
);

@include attr-x('border', 'b', 10, 'px', $border-widths);
@include attr-x('border-radius', 'br-', 10, 'px', $border-radii, false, true);

.border-light {
  border-color: #f0f0f0 !important;
}
