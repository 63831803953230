$chart-with-table-component-name: 'chart-with-table-table';
$table-header-component-name: 'table-header';
$table-header-content-component-name: 'table-header-content';
$name-cell-name: 'table-name-cell';
$table-row-component-name: 'table-row';

$chart-with-table-component: get-component-uuid(
  $chart-with-table-component-name
);
$table-header-component: get-component-uuid($table-header-component-name);
$table-header-content-component: get-component-uuid(
  $table-header-content-component-name
);
$name-cell-component: get-component-uuid($name-cell-name);
$table-row-component: get-component-uuid($table-row-component-name);

.#{$chart-with-table-component} {
  width: 100%;
  box-sizing: border-box;
  padding-top: 18px;
  background-color: $white;
  border-radius: 5px;
  border: solid 1px $color-gray-225;
  overflow: hidden;
  margin-top: 9px;
  height: 0;
  // 49px is xAxis labels + borders height
  min-height: calc(100% - 49px);
  position: relative;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 15px;
    height: 30px;
    background: linear-gradient(to top, $white, transparent);
  }

  tr.#{$table-row-component} {
    td {
      border-bottom: solid 1px $color-gray-225;
      padding: 3px;
      box-sizing: border-box;
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        border-left: none;
        padding-left: 4px;
        text-align: left;
      }

      &:last-child {
        border-right: none;
        padding-right: 12px;
      }
    }

    &:first-child td {
      padding-top: 3px;
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  &__scrollable-content {
    flex-grow: 1;
    overflow: hidden;
  }

  &__table-wrapper {
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    width: fit-content;
  }

  .ps__rail-x {
    z-index: 1;
  }

  .#{$table-header-component} {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    font-family: $font-primary-600;
    font-weight: $font-weight-L;
    font-size: $font-size-M;
    padding-bottom: 8px;
    margin-right: 15px;
    margin-left: 15px;
    box-sizing: border-box;
    border-bottom: solid 1px $color-gray-225;
    flex-shrink: 0;
    width: fit-content;
    min-width: calc(100% - 30px);
    position: relative;
    left: 0;

    .#{$table-header-content-component} {
      box-sizing: border-box;
      padding: 0 3px;
      text-align: right;
      flex-shrink: 0;
      cursor: pointer;

      &.active {
        color: $color-ispot-gray-4;
      }

      &:first-child {
        flex-grow: 1;
        padding-left: 4px;
        text-align: left;
      }

      &:not(:first-child) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
      }

      .sort-direction-indicator {
        margin-left: 1px;
        font-size: 13px;
        padding-top: 2px;
      }
    }
  }

  .#{$name-cell-component} {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__dot {
      width: 6px;
      height: 6px;
      display: inline-block;
      border-radius: 50%;
      margin: 2px 4px 2px 0;
      flex-shrink: 0;
    }

    &__name {
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.#{$chart-with-table-component}.sticky-first-column {
  .#{$table-header-component} {
    .#{$table-header-content-component} {
      &:first-child {
        position: sticky;
        left: 0;
        background-color: $color-white;
      }
    }
  }
}
