$field-wrapper-component: get-component-uuid($field-wrapper-component-name);

.chart-filter {
  .r2d2-field {
    margin-right: 20px;
    width: 200px;
  }

  .r2d2-button,
  .#{$field-wrapper-component} {
    width: 200px;
  }

  .add-metric-button {
    padding: 13px;
  }
}
