$exposure-households-and-converters-component-name: 'exposure-households-and-converters';
$exposure-households-and-converters-component: get-component-uuid(
  $exposure-households-and-converters-component-name
);

$exposure-conversion-latency-component-name: 'exposure-conversion-latency';
$exposure-conversion-latency-component: get-component-uuid(
  $exposure-conversion-latency-component-name
);

$exposure-conversion-latency-chart-component-name: 'exposure-conversion-latency-chart';
$exposure-conversion-latency-chart-component: get-component-uuid(
  $exposure-conversion-latency-chart-component-name
);

.#{$exposure-households-and-converters-component},
.#{$exposure-conversion-latency-component} {
  &-title {
    height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-subtitle {
    height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: $font-primary-400;
    font-size: $font-size-XXXM;
  }

  &-subtitle-bold {
    font-family: $font-primary-600;
  }

  &-plot-line {
    stroke-dasharray: 3 12;
  }
}

.#{$exposure-conversion-latency-chart-component} {
  position: relative;

  &__omission-alert {
    position: absolute;
    background: $color-white;
    z-index: 4;
    opacity: 1;
    border-radius: 4px;
  }
}
