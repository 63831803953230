$chart-wrapper-component-name: 'bar-chart-wrapper';
$chart-overlay-component-name: 'bar-chart-overlay';

$chart-wrapper-component: get-component-uuid($chart-wrapper-component-name);
$chart-overlay-component: get-component-uuid($chart-overlay-component-name);

.#{$chart-wrapper-component} {
  position: relative;

  .#{$chart-overlay-component} {
    position: absolute;
    z-index: 4;
    opacity: 1;
    background: $color-white;
  }
}
