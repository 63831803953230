.valueCardWrapper {
  width: 500px;
  min-height: 180px;
  border: 1px solid $color-gray-200;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  .deleteContainer {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .changeOrderContainer {
    position: absolute;
    right: -65px;
    top: 75px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .arrow {
      border: 1px solid $color-gray-200;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .arrow.arrow-up {
      transform: rotate(-90deg);
    }
    .arrow.arrow-down {
      transform: rotate(90deg);
    }
  }
}
