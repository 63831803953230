.banner-container {
  width: calc(100% - 150px);
  position: fixed;
  margin: auto;
  .banner {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
