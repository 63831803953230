$skeleton---height: 30px;
$skeleton---width: 300px;
$skeleton---background: linear-gradient(
  -90deg,
  $color-ispot-gray-3 0%,
  $color-gray-300 50%,
  $color-ispot-gray-3 100%
);
$skeleton---background-size: 400% 400%;
$skeleton---margin: 20px;
$skeleton--large---width: 90%;
$skeleton--large---height: 60px;
$skeleton--small---width: 20%;
$skeleton--small---height: 20px;

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

$skeleton-animation: pulse 1.2s ease-in-out infinite;
