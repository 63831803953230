@font-face {
  font-family: 'SourceSansProExtraLight';
  src:
    local('SourceSansProExtraLight'),
    url(./fonts/SourceSansPro-ExtraLight.otf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProLight';
  src:
    local('SourceSansProLight'),
    url(./fonts/SourceSansPro-Light.otf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProRegular';
  src:
    local('SourceSansProRegular'),
    url(./fonts/SourceSansPro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProSemibold';
  src:
    local('SourceSansProSemibold'),
    url(./fonts/SourceSansPro-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'SourceSansProBold';
  src:
    local('SourceSansProBold'),
    url(./fonts/SourceSansPro-Bold.otf) format('opentype');
}
