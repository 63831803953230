$sticky-header-component-name: 'sticky-header';

$sticky-header-component: get-component-uuid($sticky-header-component-name);

.#{$sticky-header-component} {
  position: sticky;
  z-index: $main-header---z-index;
  width: 100%;
  top: 0;
  background: $color-text-background;
  margin-top: -$feature-content---padding-top;
  padding: 15px 0;
  z-index: 6;
  box-sizing: border-box;
  box-shadow: 2px 3px 10px $color-text-background;
}
