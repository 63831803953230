$h4-component-name: 'h4';

$h4-component: get-component-uuid($h4-component-name);

.#{$h4-component} {
  width: $h4---width;
  color: $h4---color;
  font-family: $h4---font-family;
  font-size: $h4---font-size;
}
