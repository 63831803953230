$field-wrapper-component-name: 'field-wrapper';

$field-wrapper-component: get-component-uuid($field-wrapper-component-name);
$fields-component: get-component-uuid($fields-component-name);
$label-component: get-component-uuid($label-component-name);
$input-wrapper-component: get-component-uuid($input-wrapper--component-name);
$error-component: get-component-uuid($error-component-name);
$dropdown-component: get-component-uuid($dropdown-component-name);
$multiple-select-component: get-component-uuid($multiple-select-component-name);
$typeahead-component: get-component-uuid($typeahead-component-name);
$button-component: get-component-uuid($button-component-name);
$segmented-control-component: get-component-uuid(
  $segmented-control-component-name
);
$checkbox-component: get-component-uuid($checkbox-component-name);
$flex-component: get-component-uuid($flex-component-name);

.#{$field-wrapper-component} {
  width: 100%;
  margin-bottom: $form--row---margin-bottom;

  &.small {
    height: $field--small---height;
    max-height: unset;
  }

  &.auto {
    height: 100%;
    max-height: unset;
  }

  > .#{$label-component},
  .#{$fields-component} .#{$label-component} {
    margin-bottom: $field-label---margin-bottom;
    height: $field-label---height;
  }

  > .#{$flex-component}.column {
    .#{$label-component} {
      margin-bottom: $field-label---margin-bottom;
      height: $field-label---height;
    }
  }

  > .#{$flex-component}.row {
    .#{$label-component} {
      padding-right: $field-input--row--label---padding-right;
      width: $field-input--row--label---width;
      flex-shrink: 0;
      color: $field-input--row--label---color;
    }

    .#{$input-wrapper-component} {
      margin-top: 0 !important;
      margin-bottom: $form--row---margin-bottom;
    }
  }

  .#{$input-wrapper-component} {
    margin-bottom: $field-input---margin-bottom;
    margin-top: $field-label---margin-bottom + $field-label---height;
  }

  .#{$button-component} {
    margin-bottom: $field-button---margin-bottom;
    margin-top: $field-button---margin-top;
    padding: $field-button---padding;
  }

  .#{$segmented-control-component} {
    margin-bottom: $field-segmented-control---margin-bottom;
    margin-top: 10px;

    .#{$button-component} {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .#{$checkbox-component} .#{$input-wrapper-component} {
    margin-bottom: 0;
    margin-top: 0;
  }

  .#{$label-component} + .#{$input-wrapper-component},
  .#{$label-component} + .#{$dropdown-component} .#{$input-wrapper-component},
  .#{$label-component}
    + .#{$multiple-select-component}
    .#{$input-wrapper-component},
  .#{$label-component} + .#{$typeahead-component} .#{$input-wrapper-component} {
    margin-bottom: $field-input---margin-bottom;
    margin-top: 0;
  }

  .#{$error-component} {
    margin-top: 0;
    margin-bottom: 0;
    height: $field-error---height;
    width: 100%;
  }

  .#{$error-component} + .#{$input-wrapper-component},
  .#{$error-component} + .#{$dropdown-component},
  .#{$error-component} + .#{$multiple-select-component},
  .#{$error-component} + .#{$typeahead-component} {
    margin-bottom: $field-input---margin-bottom + $field-error---height;
  }
}
