$checkbox-component-name: 'checkbox';

$checkbox-component: get-component-uuid($checkbox-component-name);
$label-component: get-component-uuid($label-component-name);
$input-component: get-component-uuid($input-component-name);

.#{$checkbox-component} {
  position: relative;
  margin-top: $form--vertical---padding * 2;

  .#{$label-component} {
    cursor: pointer;
    margin-bottom: 0;
  }

  .#{$input-component}-wrapper {
    width: auto;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    padding: 0;
    position: relative;
    width: $checkbox---size;
    height: $checkbox---size;
    margin: $checkbox---margin;
    border: $form---border;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 3px;
      top: 2px;
      z-index: 1;
      width: $checkbox-check---width;
      height: $checkbox-check---height;
      border: $checkbox-check---weight solid $checkbox-check---color;
      border-top-style: none;
      border-right-style: none;
      transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
      transform: rotate(-45deg) scale(0, 0);
    }

    &:checked {
      &::before {
        transform: rotate(-45deg) scale(1, 1);
        top: $checkbox--checked-check---top;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled label,
  &.disabled input {
    cursor: not-allowed;
  }
}
