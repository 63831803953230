@use 'sass:math';

$sidebar-component-name: 'sidebar';

$sidebar-component: get-component-uuid($sidebar-component-name);
$icon-component: get-component-uuid($icon-component-name);

.#{$sidebar-component} {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  background: $sidebar---background;
  width: $sidebar---width;
  height: 100%;
  box-sizing: content-box;
  box-shadow: 1px 0 1px 1px rgba(0, 0, 0, 0.1);
  z-index: $sidebar---z-index;
  transition: width 0.3s ease-in-out;
  flex-shrink: 0;

  &-wrapper {
    overflow: hidden;
    width: $sidebar--expanded---width;
  }

  &.expanded {
    width: $sidebar--expanded---width;
  }

  &-expand-trigger {
    position: absolute;
    right: $sidebar-item--expand-trigger--right;
    top: $sidebar-item--expand-trigger--top;

    .#{$icon-component} {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }

    &.deployed {
      .#{$icon-component} {
        transform: rotate(270deg);
        transition: transform 0.3s;
      }
    }
  }

  &-item {
    position: relative;
    width: 100%;
    font-family: $sidebar-item---font-family;
    font-size: $sidebar-item---font-size;
    color: $sidebar-item---color;
    background: $sidebar---background;
    list-style: none;
    cursor: pointer;

    &-label {
      vertical-align: middle;
      height: $sidebar-item---height;
      line-height: $sidebar-item---line-height;
      color: $sidebar-item---color;

      &.active {
        font-family: $sidebar-item--active---font-family;
        color: $sidebar-item---color;
      }

      a {
        text-decoration: none;
        color: $sidebar-item---color;

        & > div {
          width: 100%;
        }
      }
    }

    &.subItem {
      padding-left: $sidebar--sub-item--padding-left;
      margin: 0;
    }

    a,
    button,
    &-wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      width: 100%;
      padding-top: $sidebar-item---padding-vertical +
        math.div($sidebar-item---margin, 2);
      padding-bottom: $sidebar-item---padding-vertical +
        math.div($sidebar-item---margin, 2);

      .icon-wrapper {
        min-width: $sidebar-item-icon---min-width;
        height: $sidebar-item-icon---height;
        margin-right: $sidebar-item-icon---margin-right;
        margin-left: $sidebar-item-icon---margin-left;
      }

      .#{$icon-component} {
        font-size: $sidebar-item-icon---font-size;
        display: inline-block;
        text-align: center;
        vertical-align: sub;
      }

      &.active {
        color: $sidebar-item--active---color;

        .#{$icon-component} {
          filter: $sidebar-item-icon-active---filter;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background: $sidebar-item--hover---background-color;
      // background-color: red;
      transition: background-color 0.15s ease-in-out;
    }

    .icon-mt-4-px {
      margin-top: 4px;
    }
  }
}
