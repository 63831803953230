$target-report-results-component-name: 'target-report-results';

$target-report-results-component: get-component-uuid(
  $target-report-results-component-name
);

.#{$target-report-results-component} {
  &-partial-data {
    margin: 20px;
  }

  &-not-enough-hh {
    margin: 20px;
    display: flex;
    justify-content: center;
  }
}

.#{get-component-uuid('tabs-tab-panel')} {
  min-height: 400px;
}
