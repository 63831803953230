@import 'theme/default/mixins/utilities.scss';

$accordion-report-component: get-component-uuid($accordion-component-name);
$edit-attribution-report-add-name-body-component-name: 'edit-attribution-report-add-name-body';
$edit-attribution-report-add-name-body-component: get-component-uuid(
  $edit-attribution-report-add-name-body-component-name
);
$edit-attribution-report-component-name: 'edit-attribution-report';
$edit-attribution-report-component: get-component-uuid(
  $edit-attribution-report-component-name
);
$inline-edit-component-name: 'inline-edit';
$inline-edit-component: get-component-uuid($inline-edit-component-name);
$kebab-drawer-component-name: 'kebab-drawer';
$kebab-drawer-component: get-component-uuid($kebab-drawer-component-name);
$report-name-input-component-name: 'field--report-name-input';
$report-name-input-component: get-component-uuid(
  $report-name-input-component-name
);
$report-toolbar-header-component-name: 'report-toolbar-header';
$report-toolbar-header-component: get-component-uuid(
  $report-toolbar-header-component-name
);

.#{$edit-attribution-report-component} {
  .ppt-icon {
    position: absolute;
    top: 5px;
    left: 10px;
  }

  &-header {
    @extend .#{$feature-component}-content-header;

    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
    margin-top: -10px;
    margin-bottom: -10px;
    flex-grow: 1;

    .#{$kebab-drawer-component} {
      @extend .ml-1;
      display: inline-block;

      .r2d2-kebab-drawer-children > * {
        margin-right: 1rem;

        &:last-child {
          @extend .mr-0;
        }
      }
    }
  }

  &-query {
    margin-top: 20px;

    .#{$accordion-report-component}-body {
      padding: 0
        (
          $accordion---padding-horizontal + $accordion-header-icon---size +
            $accordion-header-icon---padding-right
        )
        30px
        (
          $accordion---padding-horizontal + $accordion-header-icon---size +
            $accordion-header-icon---padding-right
        );
    }
  }

  &-control-segment,
  &-result {
    margin-top: 20px;

    .#{$accordion-report-component} {
      &-header {
        &-toolbar {
          .ppt-button {
            button {
              background: none;
              border: 1px solid #dadada;
              margin-bottom: 0.4em;
              margin-top: 0.4em;
              padding: 0.5rem;
              width: 100px;

              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }
      }
    }
  }

  &-control-segment {
    .#{$accordion-report-component}-body {
      padding: 30px
        (
          $accordion---padding-horizontal + $accordion-header-icon---size +
            $accordion-header-icon---padding-right
        );
    }

    & .#{$query-builder-item-component} {
      > .#{$flex-component} label {
        @extend .#{$label-component};

        padding: 0 30px 0 0;
        width: 130px;
        flex-shrink: 0;
      }
    }
  }

  &-aggregation-method {
    width: 410px;
  }

  &-control-matching {
    margin-top: 30px;
    width: 410px;
  }

  &-warning-message {
    padding-top: 20px;
    color: red;
  }

  &-not-enough-hh {
    padding: 130px 42px;
    display: flex;
    justify-content: center;
  }

  .#{$report-toolbar-header-component} {
    .r2d2-button {
      white-space: nowrap;
    }
  }
}

.#{$edit-attribution-report-add-name-body-component} {
  ##{$report-name-input-component} {
    .r2d2-field-wrapper {
      @extend .mb-0;

      .r2d2-fields {
        .r2d2-flex {
          flex-direction: row;

          label {
            @extend .pr-1;

            padding-top: 0.4em;
          }
        }
      }
    }
  }
}
