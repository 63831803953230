$query-table-component-name: 'query-table';

$query-table-component: get-component-uuid($query-table-component-name);
$table-component: get-component-uuid($table-component-name);

.#{$query-table-component} {
  .#{$table-component} {
    width: fit-content;
  }
}
