$h1-component-name: 'h1';

$h1-component: get-component-uuid($h1-component-name);

.#{$h1-component} {
  width: $h1---width;
  color: $h1---color;
  font-family: $h1---font-family;
  font-size: $h1---font-size;
}
