$top-bar-component-name: 'top-bar';

$top-bar-component: get-component-uuid($top-bar-component-name);

.#{$top-bar-component}--item {
  padding-left: 15px;

  &:first-child {
    padding-left: 0;
  }
}
