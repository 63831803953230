$loginForm-component-name: 'login-form';

$logo-component: get-component-uuid($logo-component-name);
$box-component: get-component-uuid($box-component-name);
$button-component: get-component-uuid($button-component-name);
$error-component: get-component-uuid($error-component-name);
$input-component: get-component-uuid($input-component-name);
$login-form-component: get-component-uuid($loginForm-component-name);

.#{$login-form-component} {
  margin-left: $login-form---margin-left;
  width: 100%;
  height: 100%;

  &::after,
  &::before {
    position: absolute;
    content: $login-form-border---content;
    border: $login-form---border;
    z-index: $login-form--line---z-index;
    top: $login-form---y-coord;
    width: $login-form-border---length;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &-message {
    text-align: center;
  }

  &-alert {
    font-size: $form---font-size;
    padding-top: 15px;
    color: $color-error;
  }

  .#{$box-component} {
    position: absolute;
    width: $login-form---size;
    height: $login-form---size;
    z-index: $login-form---z-index;
    top: calc(#{$login-form---y-coord} - (#{$login-form---size} / 2));

    .#{$logo-component} {
      width: $login-form-logo---width;
      margin-bottom: $login-form-logo---margin-bottom;
    }

    .#{$input-component}-wrapper {
      display: flex;
      justify-content: center;
    }

    .#{$input-component} {
      width: $login-form-input---width;
      margin-bottom: $login-form-input---margin-bottom;
    }

    .#{$input-component} + .#{$input-component} {
      margin-bottom: $login-form-input--last---margin-bottom;
    }

    .#{$button-component} {
      margin-top: $login-form-button---margin-top;
    }

    .#{$error-component} {
      margin-bottom: 0;
      margin-top: 0;
    }

    .#{$error-component} + .#{$button-component} {
      margin-top: $login-form-button--error---margin-top;
    }
  }
}

.form-inline label {
  width: 200px;
  text-align: right;
  margin-right: 10px;
  justify-content: flex-end;
}

.form-group {
  width: 400px;
}
