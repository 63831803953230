$date-presets-component-name: 'date-presets';
$date-presets-component: get-component-uuid($date-presets-component-name);

.#{$date-picker-component} {
  white-space: nowrap;

  .presets-wrapper {
    &:not(.custom) {
      display: none;
    }

    .presets-column {
      .presets-button {
        border: 0;
        cursor: pointer;
        display: block;
        outline: 0;
        border-bottom: 1px solid #eff2f7;
        padding: 0;
        background: #fff;
        width: 100%;

        &:hover {
          background-color: $color-gray-200;
        }

        outline: 0;
        line-height: 18px;
        padding: 10px 20px;
        text-align: left;
      }
    }
  }
}
