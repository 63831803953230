$box---border-radius: 5px;
$box---font-family: $font-primary;
$box---background-color: $color-white;
$box--primary---border: solid 1px $color-gray-200;
$box--primary---padding-vertical: 20px;
$box--primary---padding-horizontal: 20px;
$box--primary---padding: $box--primary---padding-vertical
  $box--primary---padding-horizontal;
$box--secondary---border-radius: 50%;
$box-title---font-family: $font-primary-400;
$box-title---font-size: $font-size-XL;
$box-title---color: $color-text;
