$sticker-trigger-component-name: 'sticker-trigger';

$sticker-trigger-component: get-component-uuid($sticker-trigger-component-name);

.#{$sticker-trigger-component} {
  &.no-pointer {
    cursor: inherit;
  }

  cursor: pointer;
}
