$toast-component: 'Toastify';

$button-component: get-component-uuid($button-component-name);

.#{$toast-component} {
  &__toast {
    &.#{$toast-component}__toast--default,
    &.#{$toast-component}__toast--success,
    &.#{$toast-component}__toast--warning,
    &.#{$toast-component}__toast--error,
    &.#{$toast-component}__toast--info {
      background: $color-white;
      color: $color-text;
      padding: 20px 15px;
      line-height: 1.3;
      box-shadow: unset;
    }

    &.#{$toast-component}__toast--info {
      border: 1px solid $color-auxiliary-info;
      border-radius: 4px;
    }

    &.#{$toast-component}__toast--success {
      border: 1px solid $color-auxiliary-success;
      border-radius: 4px;
    }

    &.#{$toast-component}__toast--error {
      border: 1px solid $color-auxiliary-error;
      border-radius: 4px;
    }

    &.#{$toast-component}__toast--warning {
      border: 1px solid $color-auxiliary-warn;
      border-radius: 4px;
    }

    &.#{$toast-component}__toast--default {
      border: 1px solid $color-gray-600;
      border-radius: 4px;
    }
  }

  &__progress-bar {
    &.#{$toast-component}__progress-bar--info {
      background: $color-auxiliary-info;
    }

    &.#{$toast-component}__progress-bar--success {
      background: $color-auxiliary-success;
    }

    &.#{$toast-component}__progress-bar--error {
      background: $color-auxiliary-error;
    }

    &.#{$toast-component}__progress-bar--warning {
      background: $color-auxiliary-warn;
    }

    &.#{$toast-component}__progress-bar--default {
      background: $color-gray-600;
    }
  }

  .#{$button-component}.close {
    color: $color-gray-375;
    position: absolute;
    right: 7px;
    top: 5px;
  }
}
