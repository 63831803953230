$pagination-component-name: 'pagination';

$pagination-component: get-component-uuid($pagination-component-name);

.#{$pagination-component} {
  width: 100%;
  display: grid;
  grid-template-areas: '. items .';
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;

  &-items {
    grid-area: items;
  }

  &-index {
    padding: $pagination-index---padding;
    min-width: $pagination-index---min-width;
    min-height: $pagination-index---min-height;
    outline: none;
    text-align: center;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;

    &.active {
      color: $pagination-index--active--color;
      font-family: $pagination-index--active--font-family;

      &:focus {
        color: $pagination-index--active--color;
      }
    }

    &.link {
      text-align: center;
      width: auto;
      text-decoration: none;
    }

    &.link:hover {
      color: $pagination-index--link--hover---color;
      background-color: $pagination-index--link--hover---background-color;
      border-radius: 6px;
    }
  }
}
