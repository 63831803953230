$chart-wrapper-component-name: 'bubble-chart-wrapper';
$chart-overlay-component-name: 'bubble-chart-overlay';
$point-tooltip: 'point-tooltip';

$chart-wrapper-component: get-component-uuid($chart-wrapper-component-name);
$chart-overlay-component: get-component-uuid($chart-overlay-component-name);

.#{$chart-wrapper-component} {
  position: relative;

  .#{$chart-overlay-component} {
    position: absolute;
    z-index: 4;
    opacity: 1;
    background: $color-white;
  }

  .#{$point-tooltip} {
    background-color: $color-white;
    background-color: #fff;
    padding: 12px;
    margin: 2px;
  }
}
