$report-toolbar-component-name: 'report-toolbar';

$report-toolbar-component: get-component-uuid($report-toolbar-component-name);

$box-component: get-component-uuid($box-component-name);
$flex-component: get-component-uuid($flex-component-name);
$button-component: get-component-uuid($button-component-name);

.#{$report-toolbar-component} {
  &-header {
    .#{$flex-component} > .#{$flex-component} {
      .#{$button-component} {
        border: $report-toolbar-header-buttons-group-button---border;
        padding: $report-toolbar-header-buttons-group-button---padding;
        height: $report-toolbar-header-buttons-group-button---height;
        line-height: $report-toolbar-header-buttons-group-button---line-height;
        margin-left: $report-toolbar-header-buttons-group-button---margin-left;

        .#{$icon-component} {
          vertical-align: $report-toolbar-header-buttons-group-button-icon---vertical-align;
        }
      }
    }
  }
}
