@import './sourceSansPro.scss';

$font-primary: 'SourceSansProRegular';
$font-primary-100: 'SourceSansProExtraLight';
$font-primary-200: 'SourceSansProLight';
$font-primary-400: 'SourceSansProSemiBold';
$font-primary-600: 'SourceSansProBold';

$font-size-XXS: 8px;
$font-size-XS: 10px;
$font-size-S: 12px;
$font-size-M: 15px;
$font-size-XM: 18px;
$font-size-XXM: 21px;
$font-size-XXXM: 24px;
$font-size-XL: 27px;
$font-size-XXL: 33px;
$font-size-XXXL: 36px;

$font-weight-XS: 200;
$font-weight-S: 300;
$font-weight-M: 400;
$font-weight-XM: 500;
$font-weight-L: 600;
$font-weight-XL: 800;
$font-weight-XXL: 900;
