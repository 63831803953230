$target-builder-component-name: 'target-builder';
$query-builder-item-component-name: 'query-builder-item';

$label-component: get-component-uuid($label-component-name);
$target-builder-component: get-component-uuid($target-builder-component-name);
$query-builder-item-component: get-component-uuid(
  $query-builder-item-component-name
);
$flex-component: get-component-uuid($flex-component-name);

.#{$target-builder-component} {
  &-body {
    padding: 30px
      (
        $accordion---padding-horizontal + $accordion-header-icon---size +
          $accordion-header-icon---padding-right
      );

    .#{$query-builder-item-component} {
      margin-bottom: $form--row---margin-bottom;
    }
  }

  &-footer {
    border-top: 1px solid $color-gray-200;
    padding: 40px
      (
        $accordion---padding-horizontal + $accordion-header-icon---size +
          $accordion-header-icon---padding-right
      );
  }

  & .#{$query-builder-item-component} {
    > .#{$flex-component} label {
      @extend .#{$label-component};

      padding-right: $query-builder-item--label---padding-right;
      width: $query-builder-item--label---width;
      flex-shrink: 0;
    }
  }
}
