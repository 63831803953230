$query-builder-component-name: 'query-builder';
$query-builder-item-component-name: 'query-builder-item';

$query-builder-component: get-component-uuid($query-builder-component-name);
$query-builder-item-component: get-component-uuid(
  $query-builder-item-component-name
);
$flex-component: get-component-uuid($flex-component-name);
$modal-component: get-component-uuid($modal-component-name);

.#{$query-builder-component} {
  &-body {
    padding: 30px
      (
        $accordion---padding-horizontal + $accordion-header-icon---size +
          $accordion-header-icon---padding-right
      );

    .#{$query-builder-item-component} {
      margin-bottom: $form--row---margin-bottom;
    }
  }

  &-datasets-segmented-control {
    min-width: 410px;
  }

  &-footer {
    border-top: 1px solid $color-gray-200;
    padding: 40px
      (
        $accordion---padding-horizontal + $accordion-header-icon---size +
          $accordion-header-icon---padding-right
      );
  }

  & .#{$query-builder-item-component} {
    > .#{$flex-component} {
      align-items: baseline;

      label {
        @extend .#{$label-component};

        flex-shrink: 0;
        padding-right: $query-builder-item--label---padding-right;
        width: $query-builder-item--label---width;
      }
    }
  }

  &.#{$modal-component}.datasets {
    width: 450px;
  }

  &-visible-items {
    &-segmented-control {
      .#{$query-builder-item-component} {
        margin-top: $form--row---margin-bottom;
      }
      max-width: 450px;
    }
  }
}
