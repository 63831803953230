@import 'components/TableWrapper/styles.scss';
@import 'components/BubbleChart/styles.scss';
@import 'components/BarChart/styles.scss';

$chart-with-table-component-name: 'chart-with-table';

$chart-with-table-component: get-component-uuid(
  $chart-with-table-component-name
);

.#{$chart-with-table-component} {
  height: auto;
  width: auto;
  background: $white;
  padding: 20px;
  box-sizing: border-box;
  display: grid;

  grid-template-columns: 60% 40%;

  @media (max-width: 1200px) {
    & {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 400px);
    }
    .r2d2-table-header {
      width: 100%;

      .r2d2-table-header-content:first-child {
        flex-grow: 0;
      }

      .r2d2-table-header-content:not(first-child) {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &.col-2 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 65%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 30%;
        }
      }
    }
    &.col-3 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 44%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 25%;
        }
      }
    }

    &.col-4 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 33%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 21%;
          max-width: 21%;
        }
      }
    }
  }
  @media (min-width: 1800px) {
    .r2d2-table-header {
      width: 100%;

      .r2d2-table-header-content:first-child {
        flex-grow: 0;
      }

      .r2d2-table-header-content:not(first-child) {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &.col-2 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 65%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 30%;
        }
      }
    }
    &.col-3 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 44%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 25%;
        }
      }
    }

    &.col-4 {
      .r2d2-table-header {
        .r2d2-table-header-content:first-child {
          min-width: 33%;
        }

        .r2d2-table-header-content:not(first-child) {
          min-width: 21%;
          max-width: 21%;
        }
      }
    }
  }
}

.bubble-chart-with-table-bubble-element {
  .highcharts-point {
    overflow: hidden;
  }
}
