$sticker-component-name: 'sticker-wrapper';

$sticker-component: get-component-uuid($sticker-component-name);

.#{$sticker-component} {
  z-index: $sticker-wrapper---z-index;

  &.header {
    z-index: $main-header-tooltip---z-index;
  }
}
