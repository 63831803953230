$tabs---background-color: $color-white;
$tabs---border: 1px solid $color-gray-200;
$tabs---border-radius: 5px;

$tabs--tab-list---padding: 20px 0 0 27px;
$tabs--tab-list---border-bottom: 1px solid $color-gray-200;

$tabs--tab-panel---font-family: $font-primary-200;

$tabs--tab-panel--h2---font-family: $font-primary-200;

$tabs--tab---border-bottom: 3px solid transparent;
$tabs--tab---padding: 6px 16px;
$tabs--tab---font-family: $font-primary-400;

$tabs--tab--selected---background-color: $color-white;
$tabs--tab--selected---color: $color-black;
$tabs--tab--selected---border-color: $color-primary;
$tabs--tab--selected--margin: 40px;

$tabs--tab--disabled---color: $color-gray-300;

$tabs--tab--focus---box-shadow: 0 0 5px $color-primary;
$tabs--tab--focus---border-color: $color-primary;

$tabs--tab--focus-after---background-color: $color-white;
