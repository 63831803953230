// Tooltip

.common-tooltip {
  font-size: $font-size-S;
  padding: 15px;
  font-family: $font-primary-600;
  min-width: 200px;

  .tooltip-title {
    font-size: $font-size-M;
  }

  .metric-title {
    font-family: 'SourceSansProLight';
    margin-right: 2px;
    line-height: 1;
  }

  .metric-value {
    font-family: $font-primary-600;
    font-weight: bold;
    line-height: 1;
    align-self: flex-end;
  }

  .metric-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tooltip-target-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .tooltip-separator {
    margin: 11px 0;
    height: 1px;
    background: $color-gray-225;
  }

  .tooltip-target-wrapper {
    margin-top: 20px;
  }

  .tooltip-metric-title-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
  }
}

.point-tooltip {
  @extend .common-tooltip;
}

.shared-tooltip {
  @extend .common-tooltip;
}
