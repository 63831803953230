@import 'theme/default/mixins/utilities.scss';

$edit-report-component-name: 'edit-report';

$edit-report-component: get-component-uuid($edit-report-component-name);

$box-component: get-component-uuid($box-component-name);
$field-component: get-component-uuid($field-component-name);
$flex-component: get-component-uuid($flex-component-name);
$button-component: get-component-uuid($button-component-name);
$h3-component: get-component-uuid($h3-component-name);
$modal-component: get-component-uuid($modal-component-name);
$accordion-component: get-component-uuid($accordion-component-name);
$kebab-drawer-component: get-component-uuid($kebab-drawer-component-name);
$report-settings-component: get-component-uuid($report-settings-component-name);
$inline-edit-component: get-component-uuid($inline-edit-component-name);

$report-toolbar-header-component-name: 'report-toolbar-header';
$report-toolbar-header-component: get-component-uuid(
  $report-toolbar-header-component-name
);

.#{$edit-report-component} {
  &-settings,
  &-query,
  &-results {
    width: 100%;

    &-chart {
      margin-top: $report-form-results-chart---margin-top;
    }

    &-export-button {
      width: max-content;
    }

    .#{$box-component} {
      margin-bottom: $report-form-section-box---margin-bottom;
    }

    .#{$h3-component} {
      margin-bottom: 0;
    }

    &-partial-data {
      margin-bottom: 20px;
    }

    &-not-enough-hh {
      margin: 100px 0;
      display: flex;
      justify-content: center;
    }
  }

  &-header {
    @extend .#{$feature-component}-content-header;

    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
    flex-grow: 1;

    .#{$inline-edit-component} {
      &:hover + .#{$kebab-drawer-component} {
        display: none;
      }
    }

    .#{$kebab-drawer-component} {
      @extend .ml-1;

      .r2d2-kebab-drawer-children > * {
        margin-right: 1rem;

        &:last-child {
          @extend .mr-0;
        }
      }
    }
  }

  .#{$report-toolbar-header-component} {
    .r2d2-button {
      white-space: nowrap;
    }
  }

  &-no-data {
    font-size: $report-form-no-data---font-size;
    height: $report-form-footer-no-data---height;
    line-height: $report-form-footer-no-data---line-height;
    color: $report-form-footer-no-data---color;
  }

  &-label {
    width: $report-form-label---width;
  }
}

.#{$modal-component} {
  .#{$edit-report-component} {
    &-audit-header,
    &-add-name-header {
      .#{$h3-component} {
        margin-bottom: 0;
      }
    }

    &-audit-body {
      padding: $report-form-modal-audit-body---padding;
      min-width: $report-form-modal-audit-body---width;
    }

    &-add-name-body {
      width: 100%;

      .#{$field-component} {
        margin-bottom: 0;
        .r2d2-fields {
          .r2d2-flex {
            flex-direction: row;

            label {
              padding-right: 1em !important;
            }
          }
        }
      }
    }
  }
}
