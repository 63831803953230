// TODO: P2-148 Create Label theme to adscribe
$top-bar-item-component-name: 'top-bar-item';

$top-bar-item-component: get-component-uuid($top-bar-item-component-name);

.#{$top-bar-item-component} {
  color: $top-bar-item---color;
  font-family: $top-bar-item---font-family;
  font-size: $top-bar-item---font-size;
  &:hover .r2d2-popover-menu {
    color: $top-bar-item---hover;
  }
}
