$multi-select-dropdown-name: 'multi-select-dropdown';
$chip-dropdown-name: 'chip-dropdown';
$multi-select-dropdown: get-component-uuid($multi-select-dropdown-name);
$chip-dropdown: get-component-uuid($chip-dropdown-name);

.#{$chip-dropdown} {
  padding: 7px 10px;
  background: $multi-dropdown-with-legend--chip-bg;
  border: 1px solid $multi-dropdown-with-legend--chip-border;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  &.--simple {
    background: none;
    border: none;
    padding: 6px 11px;
  }

  &.--selected {
    .#{$chip-dropdown}__label {
      color: $multi-dropdown-with-legend--chip-selected;
    }
  }

  &__legend {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    display: inline-block;
    margin-right: 8px;
    line-height: 16px;
  }

  &__label {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-S;
    line-height: 16px;
    text-align: left;
    color: $multi-dropdown-with-legend--chip-label;

    &.--short {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.#{$multi-select-dropdown} {
  background: $multi-dropdown-with-legend--bg;
  border: 1px solid $multi-dropdown-with-legend--border;
  border-radius: 4px;
  position: relative;

  &.--open {
    border-radius: 4px 4px 0 0;

    .#{$multi-select-dropdown}__arrow {
      transform: rotate(180deg);
    }

    .#{$multi-select-dropdown}__options {
      padding: 10px 0;
      max-height: 218px;
      border-radius: 0 0 4px 4px;
      opacity: 1;
    }
  }

  &__arrow {
    width: 0;
    height: 0;
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 10px;
    height: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &::before {
      content: '';
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: $multi-dropdown-with-legend--input--arrow transparent
        transparent transparent;
      display: block;
      margin-bottom: -10px;
    }
  }

  &__input {
    padding: 10px 34px 2px 10px;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    list-style: none;
    position: relative;
    min-height: 53px;
    cursor: pointer;

    &__placeholder {
      width: 100%;
      font-family: $font-primary;
      font-style: italic;
      font-weight: normal;
      font-size: $font-size-S;
      line-height: 16px;
      padding: 10px;
      text-align: left;
      color: $multi-dropdown-with-legend--input--placeholder;
      cursor: pointer;
    }
  }

  &__selected-chips {
    display: inline-block;
    margin: 0 8px 8px 0;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &__options {
    background: $multi-dropdown-with-legend--bg;
    overflow-y: auto;
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition:
      max-height 0.2s ease-in-out,
      padding 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    position: absolute;
    // NOTE: -1px on each side compensates $multi-select-dropdown's border
    left: -1px;
    right: -1px;
    box-sizing: border-box;
    border: 1px solid $multi-dropdown-with-legend--border;
    z-index: 1;
  }

  &__option-item {
    cursor: pointer;
    padding: 0 6px;

    &:hover {
      background: $multi-dropdown-with-legend--bg-active;
    }
  }
}
