$processing-overlay-component-name: 'processing-overlay';

$processing-overlay-component: get-component-uuid(
  $processing-overlay-component-name
);

.#{$processing-overlay-component} {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(#ffffff, 0.7);
  display: none;

  top: 0;
  left: 0;

  z-index: 20;

  &.running {
    display: block;
  }

  &__content {
    position: fixed;
    text-align: center;
    width: 180px;
    height: 110px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    .label,
    .percentage {
      font-family: $font-primary-600;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
      margin: 7px 0 0 0;
    }

    .percentage {
      font-size: 14px;
    }
  }
}
