$options-tree-component-name: 'options-tree';
$options-tree-group-component-name: 'options-tree-group';
$options-tree-item-component-name: 'options-tree-item';

$options-tree-component: get-component-uuid($options-tree-component-name);
$options-tree-group-component: get-component-uuid(
  $options-tree-group-component-name
);
$options-tree-item-component: get-component-uuid(
  $options-tree-item-component-name
);
$button-component: get-component-uuid('button');
$icon-component: get-component-uuid('icon');

.#{$options-tree-group-component} {
  display: block;
  height: auto;
  min-height: $options-tree--group---height;
  padding-top: $options-tree--group---padding-top;
  padding-bottom: $options-tree--group---padding-bottom;
  cursor: pointer;
  font-family: $options-tree--group-name---font-family;
  font-size: $options-tree--group-name---font-size;
  line-height: 32px;

  .#{$icon-component} {
    transform: rotate(-90deg);
    transition: transform 0.4s;
  }

  &:hover {
    color: $options-tree--link--focus---color;
    background-color: $options-tree--list-item--hover---background-color;

    .#{$icon-component} {
      filter: $options-tree--list-item--icon--hover---filter;
    }
  }

  &.deployed {
    .#{$icon-component} {
      transform: rotate(0);
      transition: transform 0.4s;
    }
  }

  .#{$options-tree-group-component}-name {
    padding-right: 30px;
  }

  .#{$options-tree-group-component}-name.deployed {
    font-weight: bold;
  }

  .#{$options-tree-group-component}-name::before {
    content: '+';
    font-weight: bold;
    left: 0.5rem;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-1-group::before {
    content: '+';
    font-weight: bold;
    left: 25px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-2-group::before {
    content: '+';
    font-weight: bold;
    left: 45px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-3-group::before {
    content: '+';
    font-weight: bold;
    left: 65px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-4-group::before {
    content: '+';
    font-weight: bold;
    left: 85px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-5-group::before {
    content: '+';
    font-weight: bold;
    left: 105px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-6-group::before {
    content: '+';
    font-weight: bold;
    left: 125px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-7-group::before {
    content: '+';
    font-weight: bold;
    left: 145px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.level-8-group::before {
    content: '+';
    font-weight: bold;
    left: 165px;
    position: absolute;
  }

  .#{$options-tree-group-component}-name.deployed::before {
    content: '-';
    left: 0.5rem;
  }

  .#{$options-tree-group-component}-name.deployed.level-1-group::before {
    content: '-';
    left: 25px;
  }

  .#{$options-tree-group-component}-name.deployed.level-2-group::before {
    content: '-';
    left: 45px;
  }

  .#{$options-tree-group-component}-name.deployed.level-3-group::before {
    content: '-';
    left: 65px;
  }

  .#{$options-tree-group-component}-name.deployed.level-4-group::before {
    content: '-';
    left: 85px;
  }

  .#{$options-tree-group-component}-name.deployed.level-5-group::before {
    content: '-';
    left: 105px;
  }

  .#{$options-tree-group-component}-name.deployed.level-6-group::before {
    content: '-';
    left: 125px;
  }

  .#{$options-tree-group-component}-name.deployed.level-7-group::before {
    content: '-';
    left: 145px;
  }

  .#{$options-tree-group-component}-name.deployed.level-8-group::before {
    content: '-';
    left: 165px;
  }
}

.#{$options-tree-component} {
  font-size: $options-tree---font-size;
  font-family: $options-tree---font-family;
  width: 100%;
  max-height: $options-tree--tooltip---max-height;

  .level-0 {
    padding-left: $options-tree--level-0---padding-left;
  }

  .level-1 {
    padding-left: $options-tree--level-1---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-2 {
    padding-left: $options-tree--level-2---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-3 {
    padding-left: $options-tree--level-3---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-4 {
    padding-left: $options-tree--level-4---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-5 {
    padding-left: $options-tree--level-5---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-6 {
    padding-left: $options-tree--level-6---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-7 {
    padding-left: $options-tree--level-7---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .level-8 {
    padding-left: $options-tree--level-8---padding-left;
    padding-right: $options-tree--level-0---padding-left;
  }

  .#{$options-tree-item-component} {
    align-items: center;
    color: $options-tree--list-item---color;
    cursor: pointer;
    display: flex;
    height: auto;
    min-height: $options-tree--group---height;
    padding-bottom: $options-tree--group---padding-bottom;
    padding-top: $options-tree--group---padding-top;

    &.selected:not(.is_unmatched) {
      background-color: $color-primary;
      color: white;
    }

    &.selected.is_unmatched {
      background-color: #dcb7fb40;
      color: $options-tree-item--unmatched---color;
    }

    &.is_unmatched {
      color: $options-tree-item--unmatched---color;
    }

    &:hover {
      color: $options-tree--link--focus---color;
      background-color: $options-tree--list-item--hover---background-color;

      .#{$icon-component} {
        filter: $options-tree--list-item--icon--hover---filter;
      }

      &.selected:not(.is_unmatched),
      &.selected.is_unmatched {
        background-color: $options-tree--list-item--hover---background-color;
      }
    }

    &.icon {
      padding-right: $options-tree--list-item--icon---padding-right;
    }

    &.text {
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding-top: $options-tree--group---padding-top;
      box-sizing: border-box;
    }
  }
  a,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: $options-tree--link--focus---color;
  }
}
