$sticker-component-name: 'sticker';

$sticker-component: get-component-uuid($sticker-component-name);

.#{$sticker-component} {
  &-trigger:focus,
  &-trigger button:focus {
    outline: 0;
  }

  &.tooltip {
    font-family: $sticker--tooltip--primary---font-family;
    position: relative;
    max-width: 450px;

    &::after,
    &::before {
      content: ' ';
      height: 0;
      width: 0;
      border: solid transparent;
      position: absolute;
      pointer-events: none;
    }

    &[data-popper-placement='left'],
    &[data-popper-placement='right'] {
      &::after,
      &::before {
        top: 50%;
      }
    }

    &.primary {
      background: $sticker--tooltip--primary---background;
      border-radius: $sticker--tooltip--primary---border-radius;
      font-size: $sticker--tooltip--primary---font-size;
      color: $sticker--tooltip--primary---color;
      border: $sticker--tooltip--primary---border-width solid
        $sticker--tooltip-arrow--primary---border-color;
      padding: $sticker--tooltip--primary---padding;

      &:not(.no-arrow) {
        &::after,
        &::before {
          border: $sticker--tooltip-arrow--primary---border-width solid
            transparent;
        }

        &::before {
          border-width: calc(
            #{$sticker--tooltip-arrow--primary---border-width} + #{$sticker--tooltip--primary---border-width}
          );
        }

        &[data-popper-placement='bottom-start'] {
          bottom: calc(-1 * #{$sticker--tooltip--primary---offset});

          &::after,
          &::before {
            bottom: 100%;
            left: calc(100% - #{$sticker--tooltip--primary---offset});
          }

          &::after {
            border-bottom-color: $sticker--tooltip--primary---background;
            margin-left: calc(-1 * #{5px});
          }

          &::before {
            border-bottom-color: $color-gray-375;
            margin-left: calc(
              -1 * (#{5px} + #{$sticker--tooltip-arrow--primary---border-width})
            );
          }
        }

        &[data-popper-placement='bottom'] {
          bottom: calc(-1 * #{$sticker--tooltip--primary---offset});

          &::after,
          &::before {
            bottom: 100%;
            left: 50%;
          }

          &::after {
            border-bottom-color: $sticker--tooltip--primary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }

          &::before {
            border-bottom-color: $sticker--tooltip-arrow--primary---border-color;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }
        }

        &[data-popper-placement='top'] {
          top: calc(-1 * #{$sticker--tooltip--primary---offset});

          &::after,
          &::before {
            top: 100%;
            left: 50%;
          }

          &::after {
            border-top-color: $sticker--tooltip--primary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }

          &::before {
            border-top-color: $sticker--tooltip-arrow--primary---border-color;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }
        }

        &[data-popper-placement='left'] {
          left: calc(-1 * #{$sticker--tooltip--primary---offset});

          &::after,
          &::before {
            left: 100%;
          }

          &::after {
            border-left-color: $sticker--tooltip--primary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }

          &::before {
            border-left-color: $sticker--tooltip-arrow--primary---border-color;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }
        }

        &[data-popper-placement='right'] {
          right: calc(-1 * #{$sticker--tooltip--primary---offset});

          &::after,
          &::before {
            right: 100%;
          }

          &::after {
            border-right-color: $sticker--tooltip--primary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }

          &::before {
            border-right-color: $sticker--tooltip-arrow--primary---border-color;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--primary---border-width}
            );
          }
        }
      }
    }

    &.secondary {
      background: $sticker--tooltip--secondary---background;
      border-radius: $sticker--tooltip--secondary---border-radius;
      font-size: $sticker--tooltip--secondary---font-size;
      color: $sticker--tooltip--secondary---color;
      font-family: $sticker--tooltip--secondary---font-family;
      position: relative;
      border: $sticker--tooltip--secondary---border-width solid
        $sticker--tooltip-arrow--secondary---border-color;
      padding: $sticker--tooltip--secondary---padding;

      &:not(.no-arrow) {
        &::after,
        &::before {
          border-width: $sticker--tooltip-arrow--secondary---border-width;
        }

        &::before {
          border-width: calc(
            #{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width}
          );
        }

        &[data-popper-placement='bottom'] {
          bottom: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            bottom: 100%;
            left: 50%;
          }

          &::after {
            border-bottom-color: $sticker--tooltip--secondary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-bottom-color: $sticker--tooltip-arrow--secondary---border-color;
            margin-left: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='top'] {
          top: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            top: 100%;
            left: 50%;
          }

          &::after {
            border-top-color: $sticker--tooltip--secondary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-top-color: $sticker--tooltip-arrow--secondary---border-color;
            margin-left: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='right'] {
          right: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            right: 100%;
          }

          &::after {
            border-right-color: $sticker--tooltip--secondary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-right-color: $sticker--tooltip-arrow--secondary---border-color;
            margin-top: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='left'] {
          left: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            left: 100%;
          }

          &::after {
            border-left-color: $sticker--tooltip--secondary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-left-color: $sticker--tooltip-arrow--secondary---border-color;
            margin-top: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }
      }
    }

    &.imp4ct {
      @extend .secondary;
      border: $sticker--tooltip--secondary---border-width solid
        $sticker--tooltip-arrow--imp4ct---border-color;
      padding: $sticker--tooltip--imp4ct---padding;
      white-space: $sticker--tooltip-arrow--imp4ct---white-space;

      &:not(.no-arrow) {
        &[data-popper-placement='bottom'] {
          &::before {
            border-bottom-color: $sticker--tooltip-arrow--imp4ct---border-color;
            margin-left: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='top'] {
          &::before {
            border-top-color: $sticker--tooltip-arrow--imp4ct---border-color;
          }
        }

        &[data-popper-placement='right'] {
          &::before {
            border-right-color: $sticker--tooltip-arrow--imp4ct---border-color;
          }
        }

        &[data-popper-placement='left'] {
          &::before {
            border-left-color: $sticker--tooltip-arrow--imp4ct---border-color;
          }
        }
      }
    }

    &.tertiary {
      background: $sticker--tooltip--secondary---background;
      border-radius: $sticker--tooltip--secondary---border-radius;
      font-size: $sticker--tooltip--secondary---font-size;
      color: $sticker--tooltip--secondary---color;
      font-family: $sticker--tooltip--secondary---font-family;
      position: relative;
      border: $sticker--tooltip--secondary---border-width solid
        $sticker--tooltip-arrow--secondary---border-color;
      padding: $sticker--tooltip--tertiary---padding;

      &:not(.no-arrow) {
        &::after,
        &::before {
          border-width: $sticker--tooltip-arrow--secondary---border-width;
        }

        &::before {
          border-width: calc(
            #{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width}
          );
        }

        &[data-popper-placement='bottom'] {
          bottom: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            bottom: 100%;
            left: 50%;
          }

          &::after {
            border-bottom-color: $sticker--tooltip--secondary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-bottom-color: inherit;
            margin-left: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='top'] {
          top: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            top: 100%;
            left: 50%;
          }

          &::after {
            border-top-color: $sticker--tooltip--secondary---background;
            margin-left: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-top-color: inherit;
            margin-left: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='right'] {
          right: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            right: 100%;
          }

          &::after {
            border-right-color: $sticker--tooltip--secondary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-right-color: inherit;
            margin-top: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }

        &[data-popper-placement='left'] {
          left: calc(-1 * #{$sticker--tooltip--secondary---offset});

          &::after,
          &::before {
            left: 100%;
          }

          &::after {
            border-left-color: $sticker--tooltip--secondary---background;
            margin-top: calc(
              -1 * #{$sticker--tooltip-arrow--secondary---border-width}
            );
          }

          &::before {
            border-left-color: inherit;
            margin-top: calc(
              -1 * (#{$sticker--tooltip-arrow--secondary---border-width} + #{$sticker--tooltip--secondary---border-width})
            );
          }
        }
      }
    }
  }

  &.popover {
    > ul > li,
    > p,
    > span {
      padding: $sticker--tooltip--primary---padding;
    }

    border-radius: $sticker--popover---border-radius;
    background: $sticker--popover---background;
    font-size: $sticker--tooltip--secondary---font-size;
    color: $sticker--tooltip--secondary---color;
    font-family: $sticker--popover---font-family;
    border: solid 1px $color-gray-375;
    position: relative;

    &:not(.no-arrow) {
      &::after,
      &::before {
        content: ' ';
        height: 0;
        width: 0;
        border: solid transparent;
        position: absolute;
        pointer-events: none;
        border-width: 4px;
      }

      &::before {
        border-width: 6px;
      }

      &[data-popper-placement='top'] {
        top: calc(-1 * #{$sticker--popover---offset});

        &::after,
        &::before {
          top: 100%;
          left: 50%;
        }

        &::after {
          border-top-color: $sticker--popover---background;
          margin-left: calc(-1 * #{5px});
        }

        &::before {
          border-top-color: $color-gray-375;
          margin-left: calc(
            -1 * (#{5px} + #{$sticker--popover---border-width})
          );
        }
      }

      &[data-popper-placement='left'] {
        left: calc(-1 * #{$sticker--popover---offset});

        &::after,
        &::before {
          left: 100%;
          top: 50%;
        }

        &::after {
          border-left-color: $sticker--popover---background;
          margin-top: calc(-1 * #{5px});
        }

        &::before {
          border-left-color: $color-gray-375;
          margin-top: calc(-1 * (#{5px} + #{$sticker--popover---border-width}));
        }
      }

      &[data-popper-placement='right'] {
        right: calc(-1 * #{$sticker--popover---offset});

        &::after,
        &::before {
          right: 100%;
          top: 50%;
        }

        &::after {
          border-right-color: $sticker--popover---background;
          margin-top: calc(-1 * #{5px});
        }

        &::before {
          border-right-color: $color-gray-375;
          margin-top: calc(-1 * (#{5px} + #{$sticker--popover---border-width}));
        }
      }

      &[data-popper-placement='bottom'] {
        bottom: calc(-1 * #{$sticker--popover---offset});

        &::after,
        &::before {
          bottom: 100%;
          left: 50%;
        }

        &::after {
          border-bottom-color: $sticker--popover---background;
          margin-left: calc(-1 * #{5px});
        }

        &::before {
          border-bottom-color: $color-gray-375;
          margin-left: calc(
            -1 * (#{5px} + #{$sticker--popover---border-width})
          );
        }
      }

      &[data-popper-placement='bottom-start'] {
        bottom: calc(-1 * #{$sticker--popover---offset});

        &::after,
        &::before {
          bottom: 100%;
          left: calc(100% - #{$sticker--popover---offset});
        }

        &::after {
          border-bottom-color: $sticker--popover---background;
          margin-left: calc(-1 * #{5px});
        }

        &::before {
          border-bottom-color: $color-gray-375;
          margin-left: calc(
            -1 * (#{5px} + #{$sticker--popover---border-width})
          );
        }
      }
    }
  }
}
