$multiple-text-select-component-name: 'multiple-text-select';

$multiple-text-select-component: get-component-uuid(
  $multiple-text-select-component-name
);
$button-component: get-component-uuid($button-component-name);

.#{$multiple-text-select-component} {
  &-input-wrapper {
    position: relative;
  }

  &-selected-chips-wrapper {
    margin-top: 10px;
  }
}
