$button---border-radius: $form---border-radius;
$button---font-family: $font-primary;
$button---font-size: $font-size-M;
$button---color: $color-text;
$button---background: $color-white;
$button---border: 1px solid $color-gray-200;
$button--hover---border: 1px solid $color-gray-300;
$button--disabled---color: $color-text-350;
$button--disabled---border: 1px solid $color-gray-200;

$button--small---width: 90px;
$button--small---vertical-padding: 6px;
$button--small---horizontal-padding: 10px;
$button--medium---width: 130px;
$button--medium---vertical-padding: 12px;
$button--medium---horizontal-padding: $button--medium---vertical-padding * 2;
$button--large---width: 200px;
$button--large---vertical-padding: 15px;
$button--large---letter-spacing: 1.5px;
$button--large---text-transform: uppercase;
$button--large---font-size: 18px;
$button--extra-large---width: 300px;

$button--primary---font-family: $font-primary-600;
$button--primary---color: $color-text-900;
$button--primary---background-color: $color-primary;
$button--primary---border: solid 1px $color-primary;
$button--primary--hover---background-color: $color-primary-hover;
$button--primary--disabled---border: solid 1px $color-primary-disabled;
$button--primary--disabled---background-color: $color-primary-disabled;
$button--primary--disabled---color: darken($color-primary-disabled, 5%);

$button--text---font-family: $font-primary;
$button--text---border: none;
$button--text---background: none;
$button--text---color: $color-black;
$button--text--hover---background: none;
$button--text--hover---color: $button--text---color;

$button--outline---background-color: transparent;
$button--outline---border: 1px solid $color-gray-300;
$button--outline--hover---border: 1px solid $color-gray-500;
$button--outline--disabled---border: 1px solid $color-gray-250;
$button--outline--disabled---color: $color-text-350;

$button--placeholder---color: $color-text-500;
$button--placeholder---border: 1px dashed $color-gray-500;
$button--placeholder---background: transparent;
$button--placeholder--hover---color: $color-text-800;
$button--placeholder--hover---border: 1px dashed $color-gray-800;
$button--placeholder--disable---color: $color-text-300;
$button--placeholder--disable---border: 1px dashed $color-gray-300;
$button--placeholder--large---vertical-padding: 20px;
$button--placeholder--large---horizontal-padding: $button--placeholder--large---vertical-padding *
  2;
$button--placeholder--large---padding: $button--placeholder--large---vertical-padding
  $button--placeholder--large---horizontal-padding;
$button--placeholder--large---letter-spacing: initial;
$button--placeholder--large---text-transform: unset;
$button--placeholder--large---border-radius: $button---border-radius;

$button--add---color: $color-text-500;
$button--add---border: 1px dashed $color-gray-500;
$button--add---background: transparent;
$button--add--hover---color: $color-text-800;
$button--add--hover---border: 1px dashed $color-gray-800;
$button--add--disable---color: $color-text-300;
$button--add--disable---border: 1px dashed $color-gray-300;
$button--add--large---vertical-padding: 20px;
$button--add--large---horizontal-padding: $button--add--large---vertical-padding *
  2;
$button--add--large---padding: $button--add--large---vertical-padding
  $button--add--large---horizontal-padding;
$button--add--large---letter-spacing: initial;
$button--add--large---text-transform: unset;
$button--add--large---border-radius: $button---border-radius;

$button--link---font-family: $font-primary;
$button--link---border: none;
$button--link---background: none;
$button--link---color: $color-black;
$button--link--hover---background: none;
$button--link--hover---color: $color-gray-600;
$button--link---margin-top: 20px;
$button--link---text-decoration: underline;

$button--icon-labeled---margin-right: 7px;
$button--icon-labeled---vertical-align: top;
$button--icon-labeled--hover---filter: $icon--dark---filter;

$button--icon--hover---filter: invert(59%) sepia(60%) saturate(518%)
  hue-rotate(45deg) brightness(96%) contrast(98%);
$button--icon--disabled---filter: invert(86%) sepia(0%) saturate(640%)
  hue-rotate(228deg) brightness(93%) contrast(97%);
$button--icon--primary--hover---filter: $button--icon--hover---filter;
$button--icon--primary--disabled---filter: $icon--disabled-gray---filter;
$button--icon--toolbar--hover---filter: invert(100%) sepia(0%) saturate(7500%)
  hue-rotate(1deg) brightness(101%) contrast(107%);
