$report-settings-component-name: 'report-settings';

$report-settings-component: get-component-uuid($report-settings-component-name);

.#{$report-settings-component} {
  padding: 30px
    (
      $accordion---padding-horizontal + $accordion-header-icon---size +
        $accordion-header-icon---padding-right
    );

  &-time-frame,
  &-granularity {
    width: $report-form-section-field--small---width;
  }

  &-time-zone {
    width: $report-form-section-field--big---width;
  }

  &-weighting-mode {
    width: $report-form-section-field--big---width;
  }
}
