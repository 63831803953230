$edit-dashboard-component-name: 'edit-dashboard';

$edit-dashboard-component: get-component-uuid($edit-dashboard-component-name);

.#{$edit-dashboard-component} {
  width: 30%;

  &-header {
    @extend .#{$feature-component}-content-header;
  }
}
