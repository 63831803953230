$table-component-name: 'table';
$table-row-component-name: 'table-row';
$cell-with-button-component-name: 'table-cell-with-button';

$table-component: get-component-uuid($table-component-name);
$table-row-component: get-component-uuid($table-row-component-name);
$cell-with-button-component: get-component-uuid(
  $cell-with-button-component-name
);
$icon-component: get-component-uuid($icon-component-name);
$pagination-component: get-component-uuid($pagination-component-name);
$list-actions-component: get-component-uuid($list-actions-component-name);

.#{$table-component} {
  width: $table---width;
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
  white-space: $table---white-space;

  &-pivot table {
    border-spacing: unset;
  }

  &-search {
    padding: $table-search---padding;
    margin-bottom: $table-search---margin-bottom;
    width: 100%;
  }

  table {
    width: 100%;
    table-layout: fixed;
    background: $table---background;
    border-radius: $table---border-radius;
    box-shadow: $table---box-shadow;
  }

  &-head {
    th {
      color: $table-head-item---color;
      font-family: $table-head-item---font-family;
      font-size: $table-head-item---font-size;
      padding: $table-head-item---padding-vertical 0;
      background: $table---background;
      border-bottom: $table-head-item---border-bottom;
      text-align: left;

      &.ellipsis:not(span) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.ellipsis span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.wrap:not(span) {
        white-space: normal;
        overflow: hidden;
      }

      &.wrap span {
        display: inline;
        white-space: normal;
        overflow: hidden;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }

      &:first-child {
        padding-left: $table---padding-left;
      }

      &:last-child {
        padding-right: $table---padding-right;
      }

      .sort-direction-indicator {
        font-size: 13px;
      }
    }

    .actions {
      width: 10%;
    }
  }

  &-body {
    background: $table---background;
  }

  .#{$pagination-component} {
    background: transparent;
    border: none;
    width: $table-footer---width;
    margin-top: $table-footer---margin-top;
    padding: $table-footer---padding;
  }

  &-pivot {
    .#{$table-component}-head {
      tr:first-child th {
        border-bottom: unset;
      }

      tr th {
        border-left: $table---border;
        overflow: hidden;
        padding-left: $table-body-item---padding-left;
        text-align: center;

        &:first-child {
          padding-left: 3%;
        }

        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
      }
    }

    tr td {
      border-left: $table---border;
      padding-left: $table-body-item---padding-left;
    }

    .#{$pagination-component} {
      border-top: $table---border;
      width: inherit;
    }
  }

  .#{$table-row-component} {
    &:first-child {
      td {
        padding-top: $table-body-item---padding-vertical * 2;
      }
    }

    &:last-child {
      td {
        padding-bottom: $table-body-item---padding-vertical * 2;
      }
    }

    .#{$icon-component}.primary {
      filter: invert(19%) sepia(0%) saturate(3275%) hue-rotate(315deg)
        brightness(107%) contrast(84%);
      &:hover {
        filter: invert(68%) sepia(61%) saturate(563%) hue-rotate(40deg)
          brightness(87%) contrast(90%);
      }
    }

    td {
      font-family: $table-body-item---font-family;
      font-size: $table-body-item---font-size;
      padding-top: $table-body-item---padding-vertical;
      padding-bottom: $table-body-item---padding-vertical;
      padding-right: $table-body-item---padding-right;
      text-align: left;

      &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }

      &:first-child {
        padding-left: $table---padding-left;
      }
    }

    &:hover {
      cursor: pointer;

      td {
        text-shadow: $table-body-item--hover---text-shadow;
      }
    }

    &.#{$table-component}-clickable-row:hover {
      cursor: default;
    }

    &-removable {
      &.#{$table-row-component}-removing {
        animation-name: delayedRowFade;
        animation-fill-mode: forwards;
        animation-duration: 1s;
      }

      @keyframes delayedRowFade {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      &:hover {
        cursor: default;

        .#{$cell-with-button-component}__button {
          opacity: 0.2;

          &:hover {
            opacity: 1;
          }
        }
      }

      .#{$cell-with-button-component} {
        position: relative;
        width: 100%;
        height: 100%;

        &__button {
          border: none;
          outline: none;
          background: $color-white;
          color: $color-black;
          position: absolute;
          display: block;
          right: -20px;
          top: 0;
          opacity: 0;
          height: calc(100% + 6px);
          transition: opacity 0.15s ease-in-out;
          cursor: pointer;
          z-index: 0;
        }
      }
    }
  }
}

.#{$table-component}.sticky-first-column {
  .#{$table-row-component} {
    td {
      position: sticky;
      left: 0;
      background-color: $color-white;
      &:first-child {
        z-index: 1;
      }
    }
  }
}

#r2d2-table-report_overview_table {
  th {
    &:first-child {
      border-left: $table---border;
    }

    &:last-child {
      border-right: $table---border;
    }
  }
}
