$target-size-bar-component-name: 'target-size-bar';
$target-size-bar-component: get-component-uuid($target-size-bar-component-name);

@keyframes pulse {
  100% {
    background-size: 400% 400%;
  }
}

.#{$target-size-bar-component} {
  background-color: $target-size-bar-progress---background;
  font-family: $font-primary;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  height: $target-size-bar---height;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  span {
    &:not(.value2) {
      background-color: $target-size-bar-progress---foreground;
    }

    &.value2 {
      background-color: $target-size-bar-progress---background;
      display: inline-block;
    }

    box-sizing: border-box;
    color: #fff;
    height: 100%;
    padding: 8px;
    text-align: center;
  }

  &-wrapper {
    color: $target-size-bar---color;
    width: 100%;
  }

  &.offset {
    margin-top: calc(#{$target-size-bar---top-offset} + 10px);
  }

  &.value2 {
    color: white;
    text-align: center;
    flex: 1;
  }

  .percentage-wrapper {
    position: relative;
    padding: 0;

    .value-floating {
      position: absolute;
      top: -$target-size-bar---top-offset;
      left: 50%;
      transform: translate(-50%, -50%);

      .value-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40px;

        .line {
          width: 2px;
          height: 24px;
          background-color: $target-size-bar-progress---foreground;

          &.value2 {
            background-color: $color_primary;
          }
        }

        .number {
          display: block;
          text-align: center;
          color: $target-size-bar-progress---foreground;
          background: none;
          padding: 0;

          &.value2 {
            color: $color_primary;
          }
        }
      }
    }
  }
}
