$typeahead-component-name: 'typeahead';

$typeahead-component: get-component-uuid($typeahead-component-name);

.#{$typeahead-component} {
  position: relative;
  box-sizing: content-box;
  width: 100%;

  &:not(.tooltip-wrapper) {
    &.error {
      input {
        outline: none;
        transition: border 0.5s ease-in-out;
        border: $form--error---border;
      }
    }

    input {
      margin-bottom: 0;
    }
  }

  .popover {
    left: 2px;
    top: -2px;
    max-height: $typeahead--tooltip---max-height;
    overflow: overlay;
    box-shadow: $typeahead---box-shadow;
  }

  &.tooltip-wrapper {
    min-width: $typeahead---min-width;

    > * {
      padding-top: $typeahead--list---padding-top;
      padding-bottom: $typeahead--list---padding-bottom;
    }

    left: 2px;
    top: 2px;
    max-height: $typeahead--tooltip---max-height;
    padding-bottom: 10px;
    box-shadow: $typeahead---box-shadow;
    overflow: auto;
  }
}
