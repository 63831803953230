@import 'helpers/components/styles.scss';
@import 'theme/default/theme.scss';

$app-component-name: 'app';
$app-component: get-component-uuid($app-component-name);

// TODO https://adscribe.atlassian.net/browse/P2-193: Research reset.scss
*,
*::after,
*::before {
  box-sizing: inherit;
}
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100%;
}

.#{$app-component} {
  height: 100%;

  p {
    font-size: 15px;
    font-family: $font-primary;
    color: $color-text;
  }

  a {
    color: $link-color;
    text-decoration: none;

    &:hover {
      color: $link--hover-color;
    }
  }
}
