$box-component-name: 'box';

$box-component: get-component-uuid($box-component-name);
$h3-component: get-component-uuid($h3-component-name);

.#{$box-component} {
  background-color: $box---background-color;
  border-radius: $box---border-radius;
  border: $box--primary---border;
  font-family: $box---font-family;
  height: 100%;

  &.primary {
    border-radius: $box---border-radius;
    border: $box--primary---border;
    padding: $box--primary---padding;
  }

  &.secondary {
    border-radius: $box--secondary---border-radius;
  }

  .#{$h3-component},
  &-title {
    font-family: $box-title---font-family;
    font-size: $box-title---font-size;
    color: $box-title---color;
    margin-top: 0;
  }
}
