$skeleton-form-component-name: 'skeleton-form';

$skeleton-form-component: get-component-uuid($skeleton-form-component-name);
$flex-component: get-component-uuid($flex-component-name);

.#{$skeleton-form-component} {
  width: 100%;

  .#{$flex-component} {
    height: auto;
  }
}
