$modal--overlay---transition: opacity 200ms ease-in-out;
$modal--overlay---background-color: rgba(33, 33, 33, 0.3);

$modal---box-shadow:
  0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
$modal---border-radius: 4px;
$modal---distance-from-edge: 40px;
$modal---background: $color-white;
$modal---separator: $color-gray-300 1px solid;
$modal---font-family: $font-primary;
$modal---font-size: $font-size-M;

$modal--header---padding: 16px 24px;
$modal--body---padding: $modal--header---padding;
$modal--footer---padding: $modal--header---padding;

$modal--dialog---padding: 0;
$modal--dialog---height: auto;
$modal--dialog---min-width: 50%;
