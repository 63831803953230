$uploads-component-name: 'uploads-file-details';
$uploads-component: get-component-uuid($uploads-component-name);

.#{$uploads-component} {
  label {
    padding: 7px;
    width: 210px;
  }

  &__details-row {
    margin-top: 24px;
  }

  &__segment-name {
    &__list {
      width: 100%;
    }

    &__entry {
      margin-bottom: 15px;
      border: 1px solid rgb(204, 204, 204);
    }

    &__key {
      width: 60%;
      margin-right: 24px;
      padding: 4px 0 5px 0;
      border-bottom: 1px solid #f0f0f0;
      font-size: 14px;
      line-height: 19px;
      color: #8c8c8c;
    }

    &__label {
      width: 40%;
      margin-right: 24px;
      padding: 4px 0 5px 0;
      border-bottom: 1px solid #f0f0f0;
      font-size: 14px;
      line-height: 19px;
      color: #8c8c8c;
    }
  }

  label + .field-container {
    width: 100% !important;
  }

  .inline-field-container {
    display: flex;
    margin-top: 24px;
  }

  .inline-field-container .imp4ct-advanced-settings {
    margin-top: -30px;
  }
}
