$h2-component-name: 'h2';

$h2-compoment: get-component-uuid($h2-component-name);

.#{$h2-compoment} {
  width: $h2---width;
  color: $h2---color;
  font-family: $h2---font-family;
  font-size: $h2---font-size;
  padding: 0;
  margin: 0;
}
