$chip-component-name: 'chip';

$chip-component: get-component-uuid($chip-component-name);
$icon-component: get-component-uuid($icon-component-name);

.#{$chip-component} {
  font-family: $chip---font-family;
  font-size: $chip---font-size;
  color: $chip---color;
  border: $chip---border;
  border-radius: $chip---border-radius;
  background: $chip---background;
  position: relative;
  display: inline-block;
  padding: $chip---padding;
  user-select: none;

  &.canRemove {
    padding: $chip--can-remove---padding;
  }

  &.short-content {
    white-space: nowrap;
  }

  &.primary {
    color: $chip--primary---color;
    border: $chip--primary---border;
    background: $chip--primary---background;
  }

  &.secondary {
    color: $chip--secondary---color;
    border: $chip--secondary---border;
    background: $chip--secondary---background;
  }

  &-close {
    background-color: $chip--primary---color;
    border-radius: $chip-close---border-radius;
    padding: $chip-close---padding;
    position: absolute;
    display: block;
    top: $chip-close---top;
    right: $chip-close---right;
    cursor: pointer;
    &:hover {
      background-color: $chip--primary---color-hover;
    }
    > .#{$icon-component} {
      display: block;
    }
  }
}

.draggable:hover {
  cursor: grab;
}
