$reports-list-component-name: 'reports-list';

$reports-list-component: get-component-uuid($reports-list-component-name);

.#{$reports-list-component} {
  &-header {
    @extend .#{$feature-component}-content-header;
  }

  &-dropdown-wrapper {
    width: 100px;
  }
}
