$confirmation-dialog-component-name: 'confirmation-dialog';

$confirmation-dialog-component: get-component-uuid(
  $confirmation-dialog-component-name
);
$flex-component: get-component-uuid($flex-component-name);

.#{$confirmation-dialog-component} {
  &-footer {
    &.only-confirm {
      .#{$flex-component} {
        justify-content: flex-end;
      }
    }

    &.only-cancel {
      .#{$flex-component} {
        justify-content: flex-start;
      }
    }

    &:not(.only-cancel, .only-confirm) {
      button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  &-content {
    font-family: $font-primary-200;
    font-size: $font-size-XXM;
    margin-bottom: $confirmation-dialog-content---margin-bottom;
    max-width: 800px;
  }

  &-confirm {
    align-self: flex-end;
  }
}
