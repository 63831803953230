$segmented-control---border-radius: 10px;
$segmented-control--hover---background: $color-gray-200;
$segmented-control--active---background: $segmented-control--hover---background;
$segmented-control---active--text-shadow: 0 0 0.5px $color-black;
$segmented-control---border-color: $color-gray-300;
$segmented-control---border: 1px solid $segmented-control---border-color;
$segmented-control---padding--small: 7px 10px;
$segmented-control---padding--very-small: 5px 6px;
$segmented-control---border-radius--small: 4px;
$segmented-control---height--small: auto;
