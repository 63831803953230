$skeleton-chart-bar-component-name: 'skeleton-chart-bar';

$skeleton-chart-bar-component: get-component-uuid(
  $skeleton-chart-bar-component-name
);

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}

.#{$skeleton-chart-bar-component} {
  height: $skeleton-chart-bar---height;
  width: $skeleton-chart-bar---width;
  background: $skeleton-chart-bar---background;
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;

  &.vertical {
    transform: rotate(90deg);
  }

  &.full {
    width: 100%;
  }

  &.large {
    width: $skeleton-chart-bar--large---width;
    height: $skeleton-chart-bar--large---height;
  }

  &.small {
    width: $skeleton-chart-bar--small---width;
    height: $skeleton-chart-bar--small---height;
  }
}
