$button-component-name: 'button';
$loading-span-name: 'loading-progress';

$button-component: get-component-uuid($button-component-name);
$icon-component: get-component-uuid($icon-component-name);
$loading-span-class: get-component-uuid($loading-span-name);
$sticker-trigger-component: get-component-uuid($sticker-trigger-component-name);

.#{$button-component} {
  font-family: $button---font-family;
  font-size: $button---font-size;
  color: $button---color;
  border-radius: $button---border-radius;
  border: $button---border;
  padding: $button--medium---vertical-padding
    $button--medium---horizontal-padding;
  background: $button---background;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &:not([disabled]) {
    &:hover,
    &:focus {
      border: $button--hover---border;
      outline: none;

      .#{$icon-component} {
        filter: $button--icon-labeled--hover---filter;
      }
      .#{$icon-component}.primary {
        filter: $icon--primary---filter;
      }
      .#{$icon-component}.toolbar {
        filter: $button--icon--toolbar--hover---filter;
      }
      .#{$icon-component}.loading {
        filter: $icon--primary---filter;
      }
    }
  }

  &[disabled] {
    color: $button--disabled---color;
    border: $button--disabled---border;
    cursor: not-allowed;

    & > .#{$icon-component} {
      filter: $icon--gray---filter;
    }

    & > .#{$icon-component}.loading {
      filter: $icon--gray---filter;
    }

    & > .#{$loading-span-class} {
      color: $button---color;
    }

    &:hover > .#{$icon-component} {
      filter: $icon--gray---filter;
    }
  }

  &.small {
    padding: $button--small---vertical-padding
      $button--small---horizontal-padding;
    width: auto;
  }

  &.medium {
    padding: $button--medium---vertical-padding 0;
    width: $button--medium---width;
  }

  &.large {
    padding: $button--large---vertical-padding 0;
    letter-spacing: $button--large---letter-spacing;
    text-transform: $button--large---text-transform;
    font-size: $button--large---font-size;
    width: $button--large---width;
  }

  &.extra-large {
    padding: $button--large---vertical-padding 0;
    letter-spacing: $button--large---letter-spacing;
    text-transform: $button--large---text-transform;
    font-size: $button--large---font-size;
    width: $button--extra-large---width;
  }

  &.primary {
    font-family: $button--primary---font-family;
    border: $button--primary---border;
    background-color: $button--primary---background-color;
    color: $button--primary---color;

    &:hover,
    &:focus {
      background-color: $button--primary--hover---background-color;
    }

    &[disabled] {
      color: $button--primary--disabled---color;
      background-color: $button--primary--disabled---background-color;
      border: $button--primary--disabled---border;
      cursor: not-allowed;
    }
  }

  &.toolbar {
    border: none !important;
    background-color: $color-gray-100;

    &:hover,
    &:focus {
      background-color: $color-ispot-gray-1;
      color: $button--primary---color;
    }
    &[disabled] {
      &:hover,
      &:focus {
        background-color: $color-gray-100;
        color: $button--disabled---color;
      }
    }
  }

  &.outline {
    border: $button--outline---border;
    background-color: $button--outline---background-color;

    &:hover,
    &:focus {
      border: $button--outline--hover---border;
    }

    &[disabled] {
      color: $button--outline--disabled---color;
      border: $button--outline--disabled---border;
      cursor: not-allowed;
    }
  }

  &.placeholder {
    color: $button--placeholder---color;
    border: $button--placeholder---border;
    background: $button--placeholder---background;

    &:hover,
    &:focus {
      border: $button--placeholder--hover---border;
      color: $button--placeholder--hover---color;
    }

    &[disabled] {
      color: $button--placeholder--disable---color;
      border: $button--placeholder--disable---border;
      cursor: not-allowed;
    }

    &.large {
      letter-spacing: $button--placeholder--large---letter-spacing;
      text-transform: $button--placeholder--large---text-transform;
      border-radius: $button--placeholder--large---border-radius;
      padding: $button--placeholder--large---padding;
    }
  }

  &.add {
    color: $button--add---color;
    border: $button--add---border;
    background: $button--add---background;

    &:hover {
      border: $button--add--hover---border;
      color: $button--add--hover---color;
    }

    &[disabled] {
      color: $button--add--disable---color;
      border: $button--add--disable---border;
      cursor: not-allowed;
    }

    &.large {
      letter-spacing: $button--add--large---letter-spacing;
      text-transform: $button--add--large---text-transform;
      border-radius: $button--add--large---border-radius;
      padding: $button--add--large---padding;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.text {
    font-family: $button--text---font-family;
    border: $button--text---border !important;
    background: $button--text---background;
    color: $button--text---color;
    padding: 0;

    &:hover {
      background: $button--text--hover---background;
      color: $button--text--hover---color;
    }

    &.primary {
      &:hover {
        .#{$icon-component} {
          filter: $button--icon--primary--hover---filter;
        }

        .#{$icon-component} + span {
          font-family: $font-primary-400;
        }
      }
    }
  }

  &.link,
  &.link-primary {
    font-family: $button--link---font-family;
    font-size: $font-size-M;
    font-weight: $font-weight-M;
    height: auto;
    text-align: center;
    border: $button--link---border !important;
    background: $button--link---background;
    color: $button--link---color;
    text-transform: none;
    padding: 0;
    text-decoration: $button--link---text-decoration;

    &:hover {
      background: $button--link--hover---background;
      color: $button--link--hover---color;
    }

    &.add-new-rule-button {
      text-decoration: $button--link---text-decoration !important;
    }
  }

  &.link-primary {
    color: $color-ispot-blue-1 !important;

    &:hover {
      color: $color-ispot-blue-1 !important;
    }
  }

  .#{$icon-component} {
    vertical-align: $button--icon-labeled---vertical-align;

    + span,
    + p,
    + div {
      margin-left: $button--icon-labeled---margin-right;
    }
  }
  .#{$sticker-trigger-component} {
    display: inline-flex;
    align-items: center;
  }

  &.icon {
    padding: 0;
    vertical-align: middle;
    cursor: pointer;

    &:not(.outline) {
      border: none;
      background: transparent;
    }

    &:hover > .#{$icon-component},
    &:focus > .#{$icon-component},
    &:hover > .#{$sticker-trigger-component} > .#{$icon-component},
    &:focus > .#{$sticker-trigger-component} > .#{$icon-component} {
      filter: $button--icon--hover---filter;
    }

    &[disabled] > .#{$icon-component},
    &[disabled] > .#{$sticker-trigger-component} > .#{$icon-component} {
      cursor: not-allowed;
      filter: $button--icon--disabled---filter;
    }

    &:hover > .#{$icon-component}.primary,
    &:focus > .#{$icon-component}.primary,
    &:hover > .#{$sticker-trigger-component} > .#{$icon-component}.primary,
    &:focus > .#{$sticker-trigger-component} > .#{$icon-component}.primary {
      filter: $button--icon--primary--hover---filter;
    }

    &[disabled] > .#{$icon-component}.primary,
    &[disabled] > .#{$sticker-trigger-component} > .#{$icon-component}.primary {
      filter: $button--icon--primary--disabled---filter;
    }

    .#{$icon-component}.secondary {
      filter: $icon--gray---filter;
    }

    &:hover > .#{$icon-component}.secondary,
    &:focus > .#{$icon-component}.secondary,
    &:hover > .#{$sticker-trigger-component} > .#{$icon-component}.secondary,
    &:focus > .#{$sticker-trigger-component} > .#{$icon-component}.secondary {
      filter: $icon--primary---filter;
    }

    &[disabled] > .#{$icon-component}.secondary,
    &[disabled]
      > .#{$sticker-trigger-component}
      > .#{$icon-component}.secondary {
      filter: $button--icon--disabled---filter;
    }

    &.small {
      width: $icon--small---size;
      height: $icon--small---size;
    }
  }
}
