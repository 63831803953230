$highlightCard-component-name: 'highlight-card';

$highlightCard-component: get-component-uuid($highlightCard-component-name);

.#{$highlightCard-component} {
  background-color: $highlight-card--card-background;
  border: solid 1px $highlight-card--basic--border;
  border-radius: 4px;
  width: 100%;
  height: min-content;
  flex-grow: 1;
  margin: 16px 0px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-decorator {
    content: '';
    height: 100%;
    width: 30px;
    background-color: $highlight-card--basic--background;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &-icon {
    // to reset Flex's sizing
    min-width: $highlight-card--icon--size;
    min-height: $highlight-card--icon--size;
    max-width: $highlight-card--icon--size;
    max-height: $highlight-card--icon--size;
    box-sizing: border-box;
    background-color: $highlight-card--basic--background;
    border-radius: 50%;
    margin: 30px 20px 30px 0;
  }

  &-content-container {
    color: $color-text;
    padding: 8px 8px 8px 0px;
    max-width: calc(
      100% - #{$highlight-card--icon--size + $highlight-card--decorator--size}
    );

    div.#{$highlightCard-component}-custom-content {
      font-size: $font-size-XXXM;
      font-weight: $font-weight-M;
      font-family: $highlight-card--font--content;
      width: 95%;
    }

    b {
      font-weight: $font-weight-XL;
      font-family: $highlight-card--font--content-bold;
    }
  }

  &-title {
    font-size: $font-size-XL;
    font-weight: $font-weight-L;
    font-family: $highlight-card--font--title;
    margin: 8px 0;
  }

  &-content {
    font-size: $font-size-XL;
    font-weight: $font-weight-XL;
    font-family: $highlight-card--font--content-bold;
    line-height: 1;
    margin: 8px 0;

    .#{$highlightCard-component}-title ~ & {
      margin-top: 0;
    }
  }

  &-sub-content {
    padding-left: 10px;
    color: $highlight-card--footer-color;
  }

  &-footer {
    font-size: $font-size-XXM;
    font-weight: $font-weight-S;
    font-family: $highlight-card--font--footer;
    color: $highlight-card--footer-color;
    line-height: 1;
    margin: 8px 0;

    .#{$highlightCard-component}-content ~ &,
    .#{$highlightCard-component}-title ~ & {
      margin-top: 10px;
    }
  }
}

.#{$highlightCard-component}.click-to-scroll-enabled {
  position: relative;
  .scroll-button-wrapper {
    min-width: 24px;
    min-height: 59px;
    max-width: 24px;
    max-height: 59px;
    box-sizing: border-box;
    margin-right: 30px;
    position: absolute;
    right: -30px;

    button {
      display: none;
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
    }
  }

  &:hover {
    .scroll-button-wrapper {
      button {
        display: block;
      }
    }
  }
}
