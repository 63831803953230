$report-form-component-name: 'user-form';

$report-form-component: get-component-uuid($report-form-component-name);
$checkbox-component: get-component-uuid($checkbox-component-name);
$flex-component: get-component-uuid($flex-component-name);
$label-component: get-component-uuid($label-component-name);

.#{$report-form-component} {
  width: 100%;

  &-roles,
  &-clients {
    list-style: none;
    padding: 0;

    .#{$checkbox-component} {
      .#{$flex-component} {
        justify-content: flex-start;

        .#{$label-component} {
          text-transform: none;
        }
      }
    }
  }
}
