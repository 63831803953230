$report-overview-custom-component-name: 'report-overview-custom';
$report-overview-custom-component: get-component-uuid(
  $report-overview-custom-component-name
);

.#{$report-overview-custom-component} {
  border: solid 1px #e0e0e0;
  background-color: $color-white;
  padding: 0 25px 0px 25px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  #r2d2-table-report_overview_table {
    padding: 0 !important;
  }
  #r2d2-table-report_overview_table table {
    margin: 24px auto !important;
  }

  #r2d2-table-report_overview_table table tbody tr:first-child td:first-child {
    color: $color-ispot-green-1;
  }

  #r2d2-table-report_overview_table table tbody td:first-child {
    color: $color-ispot-blue-1;
  }
}

.#{$report-overview-custom-component}.genpop-only {
  #r2d2-table-report_overview_table table tbody tr:first-child td:first-child {
    color: $color-ispot-blue-1;
  }
}
