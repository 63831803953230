$admin-component-name: 'admin';

$admin-component: get-component-uuid($admin-component-name);
$h1-component: get-component-uuid($h1-component-name);

.#{$admin-component} {
  &-content {
    padding: $admin-content---padding;

    header {
      margin-bottom: $admin-content-header---margin-bottom;
    }

    .#{$h1-component} {
      margin: 0;
    }
  }
}

.search-dropdown-container {
  .label-container {
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
}

.status-label {
  text-align: center;
  span {
    display: block;
    width: 80px;
    height: 25px;
    padding-top: 5px;
    border-radius: 5px;
  }
  .in-progress {
    background-color: #c478ff;
  }
  .completed {
    background-color: #82ff73;
  }
  .failed {
    background-color: #f3413a;
  }
}

.name-cell {
  white-space: pre-wrap;
  word-break: break-word;
}
