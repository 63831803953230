$dashboard-view-component-name: 'dashboard-view';
$dashboard-view-component: get-component-uuid($dashboard-view-component-name);

.#{$dashboard-view-component} {
  &-header {
    @extend .#{$feature-component}-content-header;
  }

  &-no-reports-message {
    font-family: $dashboard-view--no-reports-message---font-family;
    font-size: $dashboard-view--no-reports-message---font-size;
    margin-top: $dashboard-view--no-reports-message---margin-top;
    text-align: center;
  }
}
