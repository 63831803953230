$edit-role-component-name: 'edit-role';

$edit-role-component: get-component-uuid($edit-role-component-name);

.#{$edit-role-component} {
  width: 100%;

  &-header {
    @extend .#{$feature-component}-content-header;
  }
}
