$h5-component-name: 'h5';

$h5-component: get-component-uuid($h5-component-name);

.#{$h5-component} {
  width: $h5---width;
  color: $h5---color;
  font-family: $h5---font-family;
  font-size: $h5---font-size;
}
