@use 'sass:math';

$accordion-component-name: 'accordion';

$accordion-component: get-component-uuid($accordion-component-name);
$box-component: get-component-uuid($box-component-name);
$h3-component: get-component-uuid($h3-component-name);
$icon-component: get-component-uuid($icon-component-name);
$button-component: get-component-uuid($button-component-name);

$accordion---internal-border: 1px solid $color-gray-200;
$accordion---padding-horizontal: 15px;
$accordion---padding-top: 15px;
$accordion---padding-bottom: 15px;
$accordion-header-icon---padding-right: 10px;
$accordion-header-icon---size: 12px;
$accordion-header-toolbar-button-icon-labeled---size: 15px;

.#{$accordion-component} {
  @extend .#{$box-component};
  @extend .#{$box-component}, .primary;

  box-shadow: $box-shadow;
  width: 100%;
  height: auto;
  font-family: $font-primary;
  box-sizing: border-box;
  padding: 0;

  &-header {
    display: block;
    outline: none;
    border-bottom: none;
    padding-right: $accordion---padding-horizontal;

    &.open {
      border-bottom: $accordion---internal-border;
    }

    .#{$h3-component} {
      margin-bottom: 0;
      padding-top: $accordion---padding-top;
      padding-bottom: $accordion---padding-bottom;
      font-size: 20px;
    }

    &-toolbar {
      .#{$button-component} {
        width: 100%;
        margin-right: 20px;
        padding-top: $accordion---padding-top;
        padding-bottom: $accordion---padding-bottom;

        &:last-child {
          margin-right: 0;
        }

        &.text {
          .#{$icon-component} {
            width: $accordion-header-toolbar-button-icon-labeled---size;
            height: $accordion-header-toolbar-button-icon-labeled---size;
            background-size: $accordion-header-toolbar-button-icon-labeled---size
              $accordion-header-toolbar-button-icon-labeled---size;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-icon {
    transform: translateX(0) translateY(0) rotate(0deg);
    transition: transform 0.4s;
    box-sizing: border-box;
    padding-right: $accordion-header-icon---padding-right;
    width: $accordion-header-icon---size + $accordion---padding-horizontal +
      $accordion-header-icon---padding-right;
    height: $accordion-header-icon---size + $accordion---padding-top +
      $accordion---padding-bottom;
    padding-top: $accordion---padding-top;
    padding-bottom: $accordion---padding-bottom;
    padding-left: $accordion---padding-horizontal;

    .#{$icon-component} {
      display: block;
      width: $accordion-header-icon---size;
      height: $accordion-header-icon---size;
      background-size: $accordion-header-icon---size
        $accordion-header-icon---size;
    }

    &.open {
      transition: transform 0.4s;
      transform: translateX(math.div($accordion-header-icon---size, 2))
        translateY(-2px) rotate(90deg);
    }
  }

  &-title {
    flex: 1;
  }

  &-body {
    font-family: $font-primary;
    font-size: $font-size-M;
    font-weight: $font-weight-S;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-footer {
    border-top: $accordion---internal-border;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: $accordion---padding-horizontal;
    padding-right: $accordion---padding-horizontal;
  }
}
