$results-report-component-name: 'results-report';

$results-report-component: get-component-uuid($results-report-component-name);

.#{$results-report-component} {
  padding: 30px
    (
      $accordion---padding-horizontal + $accordion-header-icon---size +
        $accordion-header-icon---padding-right
    );

  &-too-much-data {
    font-size: $font-size-XL;
    height: 200px;
    color: $color-gray-800;
  }
}
