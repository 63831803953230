@import './PointsController/styles.scss';
@import './SegmentedSwitch/styles.scss';

$chart-wrapper-component-name: 'dumbbell-chart-wrapper';
$chart-overlay-component-name: 'dumbbell-chart-overlay';
$chart-omission-component-name: 'dumbbell-chart-omission';

$chart-wrapper-component: get-component-uuid($chart-wrapper-component-name);
$chart-overlay-component: get-component-uuid($chart-overlay-component-name);
$chart-omission-component: get-component-uuid($chart-omission-component-name);

.#{$chart-wrapper-component} {
  position: relative;

  .#{$chart-omission-component} {
    position: absolute;
    background: $color-white;
    z-index: 4;
    opacity: 1;
    border-radius: 4px;
  }

  .#{$chart-overlay-component} {
    position: absolute;
    z-index: 5;
    opacity: 1;
    background-color: $color-white;
    background-image: url($chart-overlay---background-image);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    box-sizing: border-box;
    border: solid 2px $color-yellow-dark;
  }
}
