$feature-component-name: 'feature';

$feature-component: get-component-uuid($feature-component-name);

.#{$feature-component} {
  height: 100%;
  overflow: hidden;

  &-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }

  &-content {
    flex: 1;
    min-height: 100%;
    padding-left: $feature-content---padding-left;
    padding-right: $feature-content---padding-right;
    padding-bottom: $feature-content---padding-bottom;
    padding-top: $feature-content---padding-top;

    &-header {
      padding-bottom: $feature-content-header---padding-bottom;

      h1 {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  &-right-side {
    height: 100%;
    flex: 1 1;
    width: 100%;
    overflow-x: auto;
  }

  &-right-side-content {
    height: 100%;
    width: 100%;
    padding-bottom: $footer---height;
    position: relative;
  }
}
