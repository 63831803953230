$alert-component-name: 'chart-omission-alert';
$alert-component: get-component-uuid($alert-component-name);

.#{$alert-component} {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;

  &__title {
    margin-top: 17px;
    margin-bottom: 9px;
    font-family: $font-primary-600;
    font-size: 18px;
  }

  &__sub-title {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: $font-primary;
    font-weight: $font-weight-M;
    font-size: $font-size-M;
  }

  &__aside-text {
    margin-top: 12px;
    margin-bottom: 0px;
    font-family: $font-primary;
    font-weight: $font-weight-M;
    font-size: $font-size-M;
  }
}
