$kebab-drawer-component-name: 'kebab-drawer';

$kebab-drawer-component: get-component-uuid($kebab-drawer-component-name);
$button-component: get-component-uuid($button-component-name);

$kebab-drawer---padding: 6px 8px;

.#{$kebab-drawer-component} {
  &-wrapper {
    display: flex;
    position: relative;
    align-items: center;

    > .#{$button-component} {
      display: block;
    }
  }

  &-open .#{$kebab-drawer-component}-wrapper {
    > .#{$button-component} {
      display: none;
      width: 0;
    }
  }

  &-menu-wrapper {
    display: flex;
    width: 0;
    overflow: hidden;
  }

  &-open .#{$kebab-drawer-component}-menu-wrapper {
    width: 100%;
    transition: width 0.1s linear;
  }

  &-children {
    display: flex;
    background-color: $color-white;
    box-sizing: content-box;
    border: $form---border;
    border-top-left-radius: $form---border-radius;
    border-bottom-left-radius: $form---border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    padding: $kebab-drawer---padding;
  }

  .#{$button-component}.moreVertical {
    position: absolute;
    left: -5px;
  }

  .#{$button-component}.close {
    background-color: $color-white;
    box-sizing: content-box;
    padding: $kebab-drawer---padding;
    padding-left: 10px;
    border: $form---border;
    border-top-right-radius: $form---border-radius;
    border-bottom-right-radius: $form---border-radius;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
