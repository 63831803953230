$foldable-list-component-name: 'foldable-list';

$foldable-list-component: get-component-uuid($foldable-list-component-name);

.#{$foldable-list-component} {
  margin-bottom: 16px;

  &-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
    margin-top: 26px;

    li {
      display: flex;
      margin-bottom: 8px;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-item-label {
    font-family: $font-primary;
    font-size: $font-size-M;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 14px;
  }

  &-accordion-header {
    cursor: pointer;
  }

  &-accordion-title {
    font-family: $font-primary;
    font-size: $font-size-M;
    color: $color-text-600;
    text-decoration: underline;
    text-transform: capitalize;
    margin-right: 5px;
  }

  &-icon {
    transform: translateX(0) translateY(0) rotate(0deg);
    transition: transform 0.4s;

    &.open {
      transition: transform 0.4s;
      transform: rotate(180deg);
    }
  }
}
