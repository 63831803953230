$button-dropdown-component-name: 'button-dropdown';
$button-dropdown-component: get-component-uuid($button-dropdown-component-name);

.#{$button-dropdown-component} {
  .btn-group {
    width: 100px;
    button {
      background-color: $color-primary;
      border: 1px solid #dadada;
      border-radius: 4px;
      margin-bottom: 0.4em;
      margin-top: 0.4em;
      padding: 0.5rem;
      color: $color-white;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-hover !important;
      }

      &.dropdown-toggle {
        color: $color-primary;
        width: initial;
      }

      &::after {
        position: relative;
        left: 15px;
      }
    }

    .dropdown-menu {
      z-index: 1;
      color: $color-primary;
      li {
        font-size: $font-size-M;
        font-weight: 400;
        font-family: $font-primary;
        width: calc(100% - 32px);
        cursor: pointer;
        &:active {
          color: unset;
          background-color: unset;
        }
      }
    }
  }
}
