$color-primary-green: #77bd22;
$color-primary-gray: #727e8d;
$color-primary: $color-primary-green;
$color-primary-100: #b4e881;
$color-primary-200: #9fcf6f;
$color-primary-600: #639d1c;
$color-primary-800: #5f971b;
$color-primary-hover: $color-primary-600;
$color-primary-disabled: $color-primary-200;
$color-white: #fff;
$color-black: #000;
$color-text-dark: #404040;
$color-text-light: #aab4b8;
$color-text-light-hover: #e8ecee;
$color-text-background: #fafbfc;
$color-dark-background: #2d3336;
$color-blue-link: #0085cf;
$color-gray-100: #fafafa;
$color-gray-200: #f0f0f0;
$color-gray-225: #e0e0e0;
$color-gray-250: #eaeaea;
$color-gray-300: #dadada;
$color-gray-350: #cacaca;
$color-gray-375: #b6b6b6;
$color-gray-400: #adadad;
$color-gray-500: #9e9e9e;
$color-gray-600: #8c8c8c;
$color-gray-666: #666;
$color-gray-700: #7b7b7b;
$color-gray-800: #4a4a4a;
$color-gray-3: #bdbdbd;
$color-ispot-gray-1: #727e8d;
$color-ispot-gray-2: #f3f5f7;
$color-ispot-gray-3: #f6f7f9;
$color-ispot-gray-4: #404040;
$color-ispot-green-1: #77bd22;
$color-ispot-blue-1: #52a2c9;
$color-yellow-dark: #e5cf00;
$color-auxiliary-success: #56dc5b;
$color-auxiliary-warn: #ffa200;
$color-auxiliary-info: $color-primary;
$color-auxiliary-error: #f3413a;
$color-background: $color-text-background;
$color-error: $color-auxiliary-error;
$color-text: $color-text-dark;
$color-text-link: #428bcb;
$color-text-link-hover: #2a6496;
$color-text-250: $color-gray-250;
$color-text-300: $color-gray-300;
$color-text-350: $color-gray-350;
$color-text-400: $color-gray-400;
$color-text-500: $color-gray-500;
$color-text-600: $color-gray-600;
$color-text-700: $color-gray-700;
$color-text-800: $color-gray-800;
$color-text-900: $color-white;
$color-text-disabled: $color-gray-250;
$color-text-error: $color-error;
$color-datepicker-range1: $color-primary-green;
$color-orange: #f48c00;
