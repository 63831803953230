$inline-edit-component-name: 'inline-edit';

$inline-edit-component: get-component-uuid($inline-edit-component-name);
$icon-component: get-component-uuid($icon-component-name);
$button-component: get-component-uuid($button-component-name);

.#{$inline-edit-component} {
  display: flex;
  align-items: center;

  &.edit-mode {
    width: 100%;
  }

  &-edit {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: thin dashed $color-gray-375;

    h1 {
      width: 100%;
    }

    input {
      background: none;
      flex: 1;
      height: auto;
      margin-top: -1px;
      padding-left: 0;
      border: none;
      outline: none;
      width: 100%;
      font-size: $font-size-XL;
      color: $h1---color;
      font-family: $h1---font-family;
    }

    .#{$icon-component} {
      margin: 0;
    }
  }

  &-value-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: thin dashed transparent;

    .#{$icon-component}.edit {
      display: none;
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      border-bottom: thin dashed $color-gray-375;

      .#{$icon-component}.edit {
        display: block;
      }
    }
  }

  &-value {
    flex: 1;
    font-size: $font-size-XL;
    color: $h1---color;
    font-family: $h1---font-family;
  }
}
