$skeleton-component-name: 'skeleton';

$skeleton-component: get-component-uuid($skeleton-component-name);

.#{$skeleton-component} {
  animation: skeleton_pulse 1.2s ease-in-out infinite;
  background: $skeleton---background;
  background-size: 400% 400%;
  height: $skeleton---height;
  margin-bottom: $skeleton---margin;
  margin-right: $skeleton---margin;
  width: $skeleton---width;

  &.vertical {
    transform: rotate(90deg);
  }

  &.full {
    width: 100%;
  }

  &.cover {
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  &.large {
    height: $skeleton--large---height;
    width: $skeleton--large---width;
  }

  &.small {
    width: $skeleton--small---width;
    height: $skeleton--small---height;
  }
}

@keyframes skeleton_pulse {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: -135% 0%;
  }
}
