$single-stat-component-name: 'single-stat';

$single-stat-component: get-component-uuid($single-stat-component-name);

.#{$single-stat-component} {
  font-family: $dashboard-single-stat---font-family;
  font-size: $dashboard-single-stat---font-size;
  color: $dashboard-single-stat---color;
  background: $dashboard-single-stat---background;
  width: 100%;

  > div {
    height: $dashboard-single-stat---font-size;
    line-height: $dashboard-single-stat---font-size;
  }
}
