$highlighted-text-component-name: 'highlighted-text';
$highlighted-text-component: get-component-uuid(
  $highlighted-text-component-name
);

.#{$highlighted-text-component} {
  &.text-class-default {
    color: $color-text;
    font-family: $font-primary;
    font-size: $font-size-M;
  }

  .colored {
    display: inline-block;
    background-color: $color-primary-100;
  }
}
