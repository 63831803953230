$options-tree---padding-left-base: 20px;
$options-tree---font-size: $font-size-M;
$options-tree---font-family: $font-primary;
$options-tree---padding-left-multiplier: 20px;
$options-tree--list-item--hover---background-color: $color-gray-200;
$options-tree--list-item--deployed---background-color: $color-gray-200;
$options-tree--list-item---color: $color-text;
$options-tree--list-item--icon---padding-right: 8px;
$options-tree--list-item--icon--hover---filter: invert(100%) sepia(0%)
  saturate(0%) hue-rotate(309deg) brightness(102%) contrast(102%);
$options-tree-item--unmatched---color: $color-gray-400;
$options-tree--link--focus---color: $color-text;
$options-tree--link--deployed---color: $color-text;
$options-tree--group---padding-top: 3px;
$options-tree--group---padding-bottom: 3px;
$options-tree--group---height: 25px;
$options-tree--group-name---padding-left: 40px;
$options-tree--group-name---font-family: $font-primary-200;
$options-tree--group-name---font-size: $font-size-M;
$options-tree--tooltip---max-height: calc(
  (
      #{$options-tree--group---height} + #{$options-tree--group---padding-bottom} +
        #{$options-tree--group---padding-top}
    ) * 10
);

$options-tree--level-0---padding-left: $options-tree---padding-left-base;
$options-tree--level-1---padding-left: $options-tree---padding-left-base +
  $options-tree---padding-left-multiplier;
$options-tree--level-2---padding-left: $options-tree---padding-left-base + 2 *
  $options-tree---padding-left-multiplier;
$options-tree--level-3---padding-left: $options-tree---padding-left-base + 3 *
  $options-tree---padding-left-multiplier;
$options-tree--level-4---padding-left: $options-tree---padding-left-base + 4 *
  $options-tree---padding-left-multiplier;
$options-tree--level-5---padding-left: $options-tree---padding-left-base + 5 *
  $options-tree---padding-left-multiplier;
$options-tree--level-6---padding-left: $options-tree---padding-left-base + 6 *
  $options-tree---padding-left-multiplier;
$options-tree--level-7---padding-left: $options-tree---padding-left-base + 7 *
  $options-tree---padding-left-multiplier;
$options-tree--level-8---padding-left: $options-tree---padding-left-base + 8 *
  $options-tree---padding-left-multiplier;
