$attribution-query-builder-component-name: 'attribution-query-builder';

$attribution-query-builder-component: get-component-uuid(
  $attribution-query-builder-component-name
);

.#{$attribution-query-builder-component} {
  & .#{$query-builder-item-component} {
    margin-bottom: 30px;

    > .#{$flex-component} label {
      @extend .#{$label-component};

      padding: 0 30px 0 0;
      width: 130px;
      flex-shrink: 0;
    }
  }

  &-datasets-segmented-control {
    min-width: 410px;
  }

  &-viewership {
    margin-top: 80px;
  }
}
