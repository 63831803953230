$multichart-component-name: 'multichart';

$multichart-component: get-component-uuid($multichart-component-name);

$multiseries-chart-component-name: 'multiseries-chart';

$multiseries-chart-component: get-component-uuid(
  $multiseries-chart-component-name
);

.#{$multichart-component},
.#{$multiseries-chart-component} {
  width: 100%;
  position: relative;
}

.#{$multichart-component} {
  &__overlay {
    position: absolute;
    background: $color-white;
    z-index: 4;
    opacity: 1;
    border: solid 1px $color-gray-300;
    border-radius: 4px;
  }
}

.#{$multiseries-chart-component} {
  &__overlay {
    position: absolute;
    background: $color-white;
    z-index: 4;
    opacity: 1;
  }
}

// SampleLegend

$sample-legend-component-name: 'sample-legend';

$sample-legend-component: get-component-uuid($sample-legend-component-name);

.#{$sample-legend-component} {
  display: flex;
  font-family: $font-primary-200;
  font-size: $font-size-S;
  justify-content: flex-end;
  position: relative;

  &-item {
    margin-left: 16px;
  }

  &-item-title {
    white-space: nowrap;
    line-height: 1;
    margin-left: 5px;
  }
}
