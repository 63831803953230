$dropdown-component-name: 'dropdown';

$dropdown-component: get-component-uuid($dropdown-component-name);
$input-component: get-component-uuid($input-component-name);
$icon-component: get-component-uuid($icon-component-name);

.#{$dropdown-component} {
  position: relative;
  box-sizing: content-box;
  width: 100%;

  &:not(.tooltip-wrapper) {
    &.error {
      input {
        outline: none;
        transition: border 0.5s ease-in-out;
        border-bottom: $dropdown--error---border;
      }
    }

    .error-text {
      color: $color-error;
      font-family: $font-primary-400;
      text-align: right;
      width: 100%;
    }

    &.button {
      .#{$input-component} {
        border-radius: $button---border-radius;
        border-bottom: unset;
        outline: none;
      }

      .#{$input-component}::placeholder {
        font-family: $button--primary---font-family;
        border: $button--primary---border;
        background-color: $button--primary---background-color;
        color: $button--primary---color;
        padding: $form--button---padding;
        font-style: normal;
        width: calc(100% - (#{$form--horizontal--button---padding} * 2));
      }

      .#{$icon-component} {
        filter: $icon--light---filter;
        right: 10px;
      }
    }

    input {
      margin-bottom: 0;
    }

    .carrot {
      transform: rotate(90deg) scale(0.8) scaleY(1.2);
    }
  }

  &.icon-loading-wrapper {
    position: absolute;
    top: $dropdown--list---padding-top;
    right: 0;
    display: inline-block;
    width: auto;
  }

  &.option-selected {
    background-color: white;
    position: absolute;
    padding-top: $dropdown--list---padding-top;
    padding-bottom: $dropdown--list---padding-bottom;
    height: 20px;
    top: -4px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.writing {
      opacity: 0;
    }
  }

  &.tooltip-wrapper {
    min-width: $dropdown---min-width;

    > * {
      padding-top: $dropdown--list---padding-top;
      padding-bottom: $dropdown--list---padding-bottom;
    }
  }

  &.popover {
    position: relative;
    left: 2px;
    max-height: $dropdown--tooltip---max-height;
    padding-bottom: 10px;
    box-shadow: $dropdown---box-shadow;
    overflow: auto;
  }

  &.popover.modalDropdown {
    z-index: 10;
  }
}
