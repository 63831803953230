$main-component-name: 'main';

$main-component: get-component-uuid($main-component-name);

.#{$main-component} {
  width: 100%;
  overflow: hidden;
  background: $main--no---background;
  flex: 1;

  &.background {
    background: $main---background;
  }
}
