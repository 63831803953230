$client-selector-component-name: 'client-selector-menu';

$client-selector-component: get-component-uuid($client-selector-component-name);
$sticker-trigger-component: get-component-uuid($sticker-trigger-component-name);

.#{$client-selector-component} {
  .#{$sticker-trigger-component} {
    margin-left: 20px;
    margin-right: 20px;
  }
}
