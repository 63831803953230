$tabs-component-name: 'tabs';
$tab-panel-component-name: 'tab-panel';
$tab-list-component-name: 'tab-list';
$tab-component-name: 'tab';

$tabs-component: get-component-uuid($tabs-component-name);
$tab-panel-component: get-component-uuid($tab-panel-component-name);
$tab-list-component: get-component-uuid($tab-list-component-name);
$tab-component: get-component-uuid($tab-component-name);

.#{$tabs-component} {
  -webkit-tap-highlight-color: transparent;
  background-color: $tabs---background-color;
  border: $tabs---border;
  border-radius: $tabs---border-radius;
  width: 100%;
  box-shadow: $box-shadow;

  &-tab-list-locale-households {
    padding: $tabs--tab-list---padding;
  }

  &-tab-list {
    border-bottom: $tabs--tab-list---border-bottom;
    margin: 0;
    padding: $tabs--tab-list---padding;
  }

  &-tab-panel {
    display: none;
    font-family: $tabs--tab-panel---font-family;

    h2 {
      font-family: $tabs--tab-panel--h2---font-family;
    }

    &-selected {
      display: block;
      padding: 0;
    }
  }

  &-tab {
    font-family: $tabs--tab---font-family;
    display: inline-block;
    border-bottom: $tabs--tab---border-bottom;
    bottom: -1px;
    color: #9a9a9a;
    position: relative;
    list-style: none;
    padding: $tabs--tab---padding;
    cursor: pointer;

    &-selected {
      background: $tabs--tab--selected---background-color;
      border-color: $tabs--tab--selected---border-color;
      color: $tabs--tab--selected---color;
    }

    &-disabled {
      color: $tabs--tab--disabled---color;
      cursor: default;
    }

    &:focus {
      box-shadow: $tabs--tab--focus---box-shadow;
      border-color: $tabs--tab--focus---border-color;
      outline: none;
    }
  }
}
