@import 'theme/default/functions/string.scss';

$css-cardinals: (
  'top': 't',
  'right': 'r',
  'bottom': 'b',
  'left': 'l',
);

// The way to use this mixin is like so:
//
// $borders: ("top": "t", "right": "r", "bottom": "b", "left": "l");
// @include attr-x('border', 'b', 10, 'px', $borders);
//
// This will give you classes like .bt-0, ..., .bt-10, .br-0, etc.
// These classes will look like:
//
// .bt-10 { border-top: 10px important!; }
@mixin attr-x(
  $attr,
  $attr-shorthand,
  $attr-count: 10,
  $unit: 'px',
  $appendix: (),
  $use-none: false,
  $split: false
) {
  $attr-list: null;
  $attribute-name: $attr;

  @for $i from 0 through $attr-count {
    $attr-value: $i;

    @each $attr-appendix, $short-appendix in $appendix {
      .#{$attr-shorthand}#{$short-appendix}-#{$i} {
        @if $split {
          $split-name: str-split($attr, '-');
          $attribute-name: '#{nth($split-name, 1)}-#{$attr-appendix}-#{nth($split-name, 2)}';
        } @else {
          $attribute-name: '#{$attr}-#{$attr-appendix}';
        }

        @if $attr-value == 0 and $use-none {
          #{$attribute-name}: none !important;
        } @else {
          #{$attribute-name}: #{$attr-value}#{$unit} !important;
        }
      }

      $attr-list: append(
        $attr-list,
        unquote('.#{$attribute-name}-#{$attr-value}'),
        comma
      );
    }
  }
}
