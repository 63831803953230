$label-component-name: 'label';

$label-component: get-component-uuid($label-component-name);

.#{$label-component} {
  color: $color-text;
  font-family: $font-primary;
  font-size: $font-size-M;
  font-weight: $font-weight-L;
  text-transform: capitalize;
  min-height: 19px;

  &.error {
    color: $color-error;
  }

  &.disabled {
    color: $color-text-disabled;
  }
}
