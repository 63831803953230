$multi-card-component-name: 'multi-card';
$base-card-component-name: 'base-card';

$multi-card-component: get-component-uuid($multi-card-component-name);

$base-card-component: get-component-uuid($base-card-component-name);

.#{$multi-card-component} {
  display: block;
  border-radius: 4px;
  width: 100%;
  background-color: $multi-card--background;
  border: solid 1px $multi-card--border;
  padding: 4px 0;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 1px 0px;
  }

  .#{$base-card-component} {
    background-color: $multi-card--background;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 65px;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 12px 16px;
    max-height: 83px;

    &:hover {
      .#{$base-card-component}__icon-click {
        button {
          display: block;
        }
      }
    }

    &__icon {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      box-sizing: border-box;
      background-color: $multi-card--icon-background;
      border-radius: 50%;
      margin-right: 16px;
    }

    &__container {
      font-family: $font-primary;
      color: $color-text;
    }

    &__title-content {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: left;
      flex-wrap: nowrap;
      height: 29px;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    &__title {
      font-size: $font-size-XL;
      font-weight: $font-weight-XL;
      font-family: $font-primary-600;
      margin: auto 0;
    }

    &__content {
      font-size: $font-size-XXXM;
      font-weight: $font-weight-L;
      font-family: $font-primary-400;
      line-height: 1;
    }

    &__subtitle {
      font-size: $font-size-M;
      font-weight: $font-weight-M;
      color: $multi-card--subtitle-color;
      line-height: 1;
      display: inline-block;
      margin: auto 0 auto 10px;
    }

    &__icon-click {
      min-width: 24px;
      min-height: 59px;
      max-width: 24px;
      max-height: 59px;
      box-sizing: border-box;
      margin-right: 3px;

      button {
        display: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
