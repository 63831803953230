$query-builder-item-component-name: 'query-builder-item';

$query-builder-item-component: get-component-uuid(
  $query-builder-item-component-name
);
$flex-component: get-component-uuid($flex-component-name);
$dropdown-component: get-component-uuid($dropdown-component-name);
$multiple-select-component: get-component-uuid($multiple-select-component-name);
$label-component: get-component-uuid($label-component-name);
$input-component: get-component-uuid($input-component-name);

.#{$query-builder-item-component} {
  .item-wrapper {
    width: 100%;

    .#{$multiple-select-component}-selector {
      width: 100%;
    }
  }

  &.small > .#{$flex-component} > .item-wrapper {
    width: $query-builder-item--small---width;
  }

  &.medium > .#{$flex-component} > .item-wrapper {
    width: $query-builder-item--medium---width;
  }

  &.large > .#{$flex-component} > .item-wrapper {
    width: $query-builder-item--large---width;
  }

  .#{$flex-component}.top {
    .#{$label-component} {
      margin-top: 5px;
    }
  }

  &.order-by {
    .order-by-type {
      width: $query-builder-item--medium---width;
    }

    .order-by-value {
      width: $query-builder-item--small---width;
      margin-left: 40px;
    }
  }
}
