$input-component-name: 'input';
$input-wrapper--component-name: 'input-wrapper';

$input-component: get-component-uuid($input-component-name);
$input-wrapper-component: get-component-uuid($input-wrapper--component-name);
$icon-component: get-component-uuid($icon-component-name);
$button-component: get-component-uuid($button-component-name);

.#{$input-component} {
  padding: $form---padding;
  border-top: unset;
  border-right: unset;
  border-left: unset;
  border-bottom: $form---border;
  border-radius: 0;
  box-shadow: $form---box-shadow;
  background-color: unset;
  height: $form---height;
  font-size: $font-size-M;
  font-family: $font-primary;
  font-weight: $font-weight-S;
  color: $form---color;
  width: 100%;
  text-align: $form---text-align;
  transition: border 0.5s ease-in-out;
  box-sizing: content-box;

  &-wrapper {
    position: relative;
    display: block;
    width: 100%;
    box-sizing: content-box;

    & .#{$icon-component}:first-child {
      position: absolute;
      top: 24%;

      & + input.select {
        padding-left: 24px;
      }
    }
  }

  &::placeholder {
    color: $color-text-350;
    font-style: italic;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid $form--focus---outline-color;
  }

  &.error {
    transition: border 0.5s ease-in-out;
    border-bottom: $form--error---border;
  }

  &[disabled] {
    border-bottom: $form--disabled---border;
    color: $color-text-disabled;
    cursor: not-allowed;

    &.select + .#{$icon-component} {
      filter: $icon--disabled-gray---filter;
    }
  }

  &:not([disabled]) {
    &.select {
      cursor: pointer;
    }
  }

  &[readonly] {
    outline: none $color-gray-300;
  }

  &.search + .#{$icon-component},
  &.search + .#{$button-component},
  &.select + .#{$icon-component} {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
  }

  &.select + .#{$icon-component} {
    top: 10px;
  }

  &.search {
    + .#{$icon-component} {
      display: block;
      top: 0;
    }

    + .small {
      transform: rotate(0deg);
      top: 10px;
    }

    + .#{$button-component} {
      display: block;
      top: 0;
      cursor: pointer;
      margin: 0;
      padding: 0;
      &.close {
        top: 10px;
      }
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  &.truncating {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
