$card-content-component-name: 'card-content';
$card-content-component: get-component-uuid($card-content-component-name);

$omission-alert-card-component-name: 'omission-alert-card';
$omission-alert-card-component: get-component-uuid(
  $omission-alert-card-component-name
);

.#{$card-content-component}.bold-text {
  font-family: $font-primary-600;
}

.#{$card-content-component}.ellipsis {
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
}

.#{$omission-alert-card-component} {
  margin: 16px 0px;
}
