$chart-helper-component-name: 'chart-helper';

$chart-helper-component: get-component-uuid($chart-helper-component-name);

.#{$chart-helper-component} {
  font-family: $button---font-family;
  font-size: $button---font-size;
  color: $button---color;
  border-radius: $button---border-radius;
  border: $button---border;
  padding: 5px 10px;
  cursor: pointer;
  background: $color-ispot-gray-3;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &-icon {
    height: 20px;
    width: 20px;
    background: $color-white;
    border-radius: $button---border-radius;
    border: $button---border;
    margin-right: 8px;
    box-sizing: border-box;
  }
}
