$smart-selection: get-component-uuid('smart-selection');
$tooltip: get-component-uuid('sticker-trigger');

.#{$smart-selection} {
  background-color: #f0f0f0;
  padding: 10px;
  align-items: stretch;

  .#{$tooltip},
  span {
    display: flex;
  }

  button {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #f0f0f0;
    padding: 7.5px 15px;
    cursor: pointer;
    text-align: start;
    width: 100%;

    &.active {
      background: #f7f7f7;
    }
  }
}
