$dashboard-report-form-component-name: 'dashboard-report-form';
$dashboard-report-form-compoment: get-component-uuid(
  $dashboard-report-form-component-name
);

$field-component: get-component-uuid($field-component-name);
$button-component: get-component-uuid($button-component-name);

.#{$dashboard-report-form-compoment} {
  .#{$field-component} {
    margin-right: $form--column---margin-right;
  }
  .#{$button-component} {
    white-space: nowrap;
  }
}
