$dashboard-panel-component-name: 'dashboard-panel';

$dashboard-panel-component: get-component-uuid($dashboard-panel-component-name);
$flex-component: get-component-uuid($flex-component-name);
$sticker-trigger-component: get-component-uuid($sticker-trigger-component-name);
$box-component: get-component-uuid($box-component-name);
$popover-menu-content-component: get-component-uuid(
  $popover-menu-content-component-name
);

.#{$dashboard-panel-component} {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: $box-shadow;
  border-radius: 5px;

  .#{$box-component} {
    height: inherit;
    padding: 0;
  }

  &-header {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: $dashboard-panel-header---padding;

    h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
      margin: 0;
    }

    .#{$dashboard-panel-component}-header-end {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .#{$dashboard-panel-component}-header-end
      .#{$flex-component}
      div:last-child,
    .#{$sticker-trigger-component} {
      height: 20px;
    }
  }

  .last-executed-subtitle {
    color: gray;
    font-size: 11px;
    font-weight: 500;
  }
  .report-details-subtitle {
    max-width: 70%;
    font-size: 12px;
    font-weight: 500;
  }

  .execution-progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    .progress-container,
    .label-container {
      margin: 10px;
      color: $color-primary;
      font-size: 20px;
      font-weight: 800;
      text-align: center;
    }
    .progress-container {
      width: 80px;
      display: flex;
      align-items: center;
    }
  }

  &-title {
    flex-grow: 1;
  }

  &-hover-details {
    padding-right: $dashboard-panel-hover-details---padding-right;
  }

  &-header-end {
    overflow: auto;
    height: 36px;
    .report-details-icon-wrapper {
      padding-top: 5px;
    }
  }

  &-date-range {
    font-family: $dashboard-panel-date-range---font-family;
    color: $dashboard-panel-date-range---color;
    padding-right: $dashboard-panel-date-range---padding-right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-body {
    padding: $dashboard-panel-body---padding;
    overflow: hidden;

    &-is-tablet,
    &-is-single-stat {
      overflow: auto;
    }

    .partial-data {
      margin-bottom: 20px;
    }

    .not-enough-hh {
      margin: 20px;
      display: flex;
      justify-content: center;
    }
  }
}

.#{$popover-menu-content-component} {
  &.config-panel,
  &.general-config-panel {
    box-shadow: 0 0 4px 1px #00000029;
  }
}
