@import './components/TopPersuadableAudiences/styles.scss';
@import './components/Report/styles.scss';

$executive-summary-component-name: 'executive-summary';
$executive-summary-component: get-component-uuid(
  $executive-summary-component-name
);

.executive-summary-report-overview {
  box-shadow: $box-shadow;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.#{$executive-summary-component} {
  background: $color-white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  box-shadow:
    0 0px 3px rgba(0, 0, 0, 0.12),
    0 0px 3px rgba(0, 0, 0, 0.24);

  &-toggle-wrap {
    position: absolute;
    transform: translateY(-50%);
    z-index: 2;
    padding-right: 20px;
  }

  .top-performers-section {
    .header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: left;
      .title-container {
        width: 120px;
        height: 20px;
        position: relative;
        top: 6px;
      }
      .dropdown-container {
        width: calc(100% - 120px);
      }
    }
    .cost-data-label {
      font-weight: 900;
      padding-left: 10px;
    }
  }

  .total-campaign-section,
  .delivery-and-outcomes-section {
    position: relative;
    top: 5px;
    .card-wrapper {
      position: relative;
      top: 7px;
    }
  }
}
