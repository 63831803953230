$segmented-switch-component-name: 'segmented-switch';

$segmented-switch-component: get-component-uuid(
  $segmented-switch-component-name
);
$button-component: get-component-uuid($button-component-name);

.#{$segmented-switch-component} {
  height: 180px;
  width: 232px;
  padding: 10px;
  gap: 5px;
  flex-shrink: 0;

  .#{$button-component} {
    border-radius: 4px;
    border: none;
    padding: 0;
    height: 50%;
    width: 100%;
    color: $color-gray-600;
    font-family: $font-primary-600;

    &.active {
      color: $color-text;
      border: solid 1px $color-gray-3;
      background: $color-gray-200;
    }
  }

  &-button-content {
    gap: 5px;
  }
}
