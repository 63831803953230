.r2d2-uploads {
  .form-container {
    margin-top: 30px;
    .inline-field-container {
      display: flex;
      height: 75px;
      .label-container {
        width: 250px;
        display: flex;
        align-items: flex-start;
        font-weight: bold;
      }
      .field-container {
        width: calc(100% - 250px);
        display: flex;
        align-items: center;
        .s3-inline-container {
          width: 100px;
          margin-left: 20px;
          margin-right: 20px;
        }
        .s3-inline-container.input {
          width: 400px;
        }
      }
      .dataset-id-field {
        pointer-events: none;
        user-select: none;
        input {
          color: $color-gray-600;
        }
      }
    }

    .inline-error-container {
      display: flex;
      .label-container {
        width: 250px;
      }
      .error-container {
        display: block;
        ol {
          margin: 0;
          padding-left: 15px;
          li {
            font-family: $font-primary-600;
            padding-bottom: 20px;
            color: $color-auxiliary-error;
          }
        }
        p {
          font-family: $font-primary-400;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: $color-gray-700;

          .faq-link {
            color: $color-blue-link;
            text-decoration: underline;
          }
        }
      }
    }

    .browse-container {
      display: flex;
      height: 140px;
      .label-container {
        width: 250px;
      }
      .browse-input-container {
        margin-bottom: 15px;
        .hidden-input {
          display: none;
        }
        .file-name {
          font-family: $font-primary-400;
          display: inline-block;
          margin-left: 12px;
          font-style: normal;
          font-weight: 400;
          font-size: $font-size-M;
          line-height: 19px;
          color: #383b41;
        }
      }
      .browse-text-container {
        margin-top: 10px;
        color: $color-gray-700;
        font-weight: 600;
        a {
          padding-left: 8px;
          color: $color-primary-600;
          text-decoration: none;
        }
      }
    }
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
  }

  .inline-alert-container {
    width: 550px;
    height: 80px;
    display: flex;
    align-items: center;
  }

  .top-inline-alert-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 80px;
    left: 0px;
  }

  section.inline-field-container.for-impact {
    height: initial;
    display: flex;

    & > aside {
      width: 100%;
    }

    article {
      display: flex;
      gap: 24px;

      & > :nth-child(2) {
        width: 40%;
      }

      & > :first-child {
        width: 60%;
      }
    }
  }
}
