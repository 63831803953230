$summary-card-component-name: 'summary-card';

$summary-card-component: get-component-uuid($summary-card-component-name);

.#{$summary-card-component} {
  padding: 20px;
  background: $color-white;
  border: 1px solid $color-gray-200;
  border-radius: 5px;
  height: auto;

  &-button {
    border-color: $color-black;
  }

  &-header {
    margin-bottom: 16px;
    font-family: $font-primary;
    font-size: $font-size-M;
    font-weight: $font-weight-M;

    p {
      margin-bottom: 4px;
      margin-top: 0;
    }
  }

  &-title {
    font-family: $font-primary;
    font-size: $font-size-XXM;
    font-weight: $font-weight-L;
  }
}
