$table-border-color: $color-gray-200;

$table---background: $color-white;
$table---width: 100%;
$table---padding-left: 15px;
$table---padding-right: 15px;
$table---padding-vertical: 15px;
$table---border-radius: 5px;
$table---border: solid 1px $table-border-color;
$table---white-space: nowrap;
$table---box-shadow: $box-shadow;

$table-search---padding: 1px;
$table-search---margin-bottom: 24px;

$table-head-item---border-bottom: 2px solid $table-border-color;
$table-head-item---color: $color-text;
$table-head-item---font-family: $font-primary-600;
$table-head-item---font-size: $font-size-M;
$table-head-item---padding-vertical: $table---padding-vertical;
$table-head-item---padding-right: 1%;

$table-body---padding-bottom: 42px;
$table-body---border-bottom: 2px solid $table-border-color;

$table-body-item---font-family: $font-primary-200;
$table-body-item---font-size: $font-size-M;
$table-body-item---padding-vertical: 10px;
$table-body-item---padding-left: $table-head-item---padding-right;
$table-body-item---padding-right: $table-head-item---padding-right;
$table-body-item--hover---text-shadow: 0 0 0.5px $color-black;
$table-body-item-action--hover---filter: $icon--dark---filter;

$table-footer---border: 1px solid $table-border-color;
$table-footer---width: calc(100% - 6px);
$table-footer---margin-top: 15px;
$table-footer---padding: 3px 0;
