$skeleton-chart-bars-component-name: 'skeleton-chart-bars';

$skeleton-chart-bars-component: get-component-uuid(
  $skeleton-chart-bars-component-name
);
$skeleton-chart-bar-component: get-component-uuid(
  $skeleton-chart-bar-component-name
);

.#{$skeleton-chart-bars-component} {
  .#{$skeleton-chart-bar-component} {
    margin-bottom: $skeleton-chart-bars-bar---margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
