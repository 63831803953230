$toggle-is-public-component-name: 'toggle-is-public';

$toggle-is-public-component: get-component-uuid(
  $toggle-is-public-component-name
);
$sticker-trigger-component: get-component-uuid($sticker-trigger-component-name);

.#{$toggle-is-public-component} {
  display: flex;
  padding-left: 1px;

  .#{$sticker-trigger-component} {
    display: flex;
  }

  &.public {
    padding-left: 0;
  }
}
