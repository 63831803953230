$dashboard-panels-component-name: 'dashboard-panels';

$dashboard-panels-component: get-component-uuid(
  $dashboard-panels-component-name
);
$box-component: get-component-uuid($box-component-name);

.#{$dashboard-panels-component} {
  position: relative;

  .react-grid-item .#{$box-component} {
    padding: 15px;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: $dashboard-panels--resizable-handle---width;
    height: $dashboard-panels--resizable-handle---height;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: '';
    position: absolute;
    right: 5px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid $dashboard-panels--resizable-handle---color;
    border-bottom: 2px solid $dashboard-panels--resizable-handle---color;
  }

  .react-grid-item.react-grid-placeholder {
    background: $dashboard-panels--placeholder---background;
    opacity: 0.2;
    z-index: $dashboard-panels--placeholder---z-index;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .drag-handle {
    cursor: move;
  }
}
