@import 'theme/default/color.scss';
@import 'theme/default/mixins/borders.scss';
@import 'theme/default/utilities/borders.scss';
@import 'theme/default/utilities/margins.scss';
@import 'theme/default/utilities/padding.scss';
@import 'theme/default/utilities/text.scss';
@import './components/ChartHelper/styles.scss';
@import './AudienceDemographics/styles.scss';
@import './CampaignExposure/styles.scss';
@import './ExecutiveSummary/styles.scss';
@import './CampaignDeliveryOutcomes/style.scss';

$bold-font-weight: 700;
$breakdown-component-name: 'breakdown';
$breakdown-component: get-component-uuid($breakdown-component-name);
$button-component-name: 'button';
$button-component: get-component-uuid($button-component-name);
$conversion-plot-font-size: 14; // px
$conversion-plot-height: 300; // px
$conversion-table-component-name: 'table-conversion_table';
$conversion-table-component: get-component-uuid(
  $conversion-table-component-name
);
$highcharts-title-font-size: 12px;
$highcharts-title-color: #666;
$lift-percentage-table-height: 429px;
$overview-component-name: 'overview';
$overview-component: get-component-uuid($overview-component-name);
$segmented-control-component-name: 'segmented-control';
$segmented-control-component: get-component-uuid(
  $segmented-control-component-name
);
$multi-select-dropdown-name: 'multi-select-dropdown';
$multi-select-dropdown-component: get-component-uuid(
  $multi-select-dropdown-name
);

$section-border-divider: 1px solid #dadada;
$tab-panel-component-name: 'tabs-tab-panel';
$tab-panel-component: get-component-uuid($tab-panel-component-name);
$target-color: #54c1e8;
$lift-light-gray: #e9e9e9;
$lift-dark-gray: #d8d8d8;

.no-visualization {
  padding: 130px 42px;
  display: flex;
  justify-content: center;
}

.#{$breakdown-component},
.#{$overview-component} {
  .section-title-container {
    display: flex;
    border-top: $section-border-divider;
    .btn-wrapper {
      padding-top: 30px;
      border-bottom: $section-border-divider;
      width: 360px;
      .btn-content {
        width: 190px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .legend {
    div {
      margin: 10px;
    }

    span {
      width: 13px;
      height: 14px;
      float: left;
      margin-right: 5px;
      margin-top: 3px;
    }

    &.delivery-legend,
    &.lift-overview-legend {
      display: flex;
      flex-direction: row-reverse;

      .not-converted {
        background-color: $target-color;
      }
    }

    &.conversion-legend {
      display: flex;
      justify-content: flex-end;

      .not-converted {
        background-color: $color-gray-300;
      }
    }

    &.lift-overview-legend {
      &.target {
        .not-converted {
          background-color: $lift-light-gray;
        }
      }

      .not-converted {
        background-color: $lift-dark-gray;
      }
    }

    &.target {
      .converted {
        background-color: $target-color;
      }
    }

    .converted {
      background-color: $color-primary;
    }
  }

  .omission-message-overview {
    margin-left: 35px;
    margin-top: 30px;
  }

  .omission-message-breakdown {
    margin-left: 25px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  [role='tabpanel'] {
    .container-fluid {
      min-height: 675px;
    }
  }
}

.#{$overview-component} {
  h2,
  .header-row {
    @extend .bbw-1;
    @extend .pb-1;
    @extend .pl-2;
    @extend .pt-2;

    @include border-style('solid', 'bottom');

    border-color: $color-gray-300;
  }

  h4 {
    @extend .mb-0;
    @extend .mt-1;

    font-size: $font-size-S;
    font-family: $font-primary-600;
  }

  .chart-container {
    @extend .bbw-1;
    @extend .blw-1;
    @extend .brw-1;
    @extend .btw-1;

    @include border-style('solid', 'bottom');
    @include border-style('solid', 'left');
    @include border-style('solid', 'right');
    @include border-style('solid', 'top');

    border-color: $color-gray-300;
    border-radius: 5px;

    &.adjacent {
      &:first-child {
        @extend .ml-3;
        @extend .mr-1;
      }

      &:last-child {
        @extend .ml-1;
        @extend .mr-3;
      }
    }

    &.stacked {
      &:first-child {
        @extend .br-tl-0;
        @extend .br-tr-0;
      }

      &:last-child {
        @extend .bbw-0;
        @extend .br-bl-0;
        @extend .br-br-0;

        margin-bottom: -1px;
      }
    }

    .vertical-label,
    .vertical-label-converted {
      transform: rotate(-90deg);
      transform-origin: 0 0;
      position: absolute;
      left: 1.3em;
      font-size: #{$conversion-plot-font-size}px;
      width: 100px;
    }

    .vertical-label {
      bottom: #{$conversion-plot-font-size}px;
    }

    .vertical-label-converted {
      bottom: #{$conversion-plot-height - $conversion-plot-font-size}px;
    }
  }

  .header-row {
    h2 {
      @extend .bbw-0;
      @extend .pb-0;

      padding-left: 0.5em !important;
      padding-top: 0.25em !important;
    }
  }

  .mosaic-stats {
    @extend .text-right;
    @extend .blw-1;
    @extend .pt-2;

    @include border-style('solid', 'left');

    border-color: $color-gray-300;

    h5 {
      @extend .mb-1;
      @extend .mt-0;

      font-size: 10px;
    }

    .btn-group {
      button {
        padding: 6px 1px 6px 0;
        width: 100%;
      }

      button:not(:last-child) {
        border-bottom: none;
        border-radius: 4px 4px 0 0;
      }

      button:last-child {
        border-radius: 0 0 4px 4px;
      }

      .primary {
        background-color: #8745c6;
        font-family: $font-primary;
      }

      .outline {
        color: #8745c6;
      }

      &.target {
        .primary {
          background-color: $target-color;
          border-color: $target-color;
          font-family: $font-primary;
        }

        .outline {
          color: $target-color;
        }
      }
    }
  }

  .#{$table-component},
  ##{$table-component}-report_overview_table {
    @extend .pl-3;
    @extend .pr-3;

    &#r2d2-table-report_overview_table {
      table {
        margin: 32px auto;

        .r2d2-table-head {
          tr {
            &:first-child {
              th {
                &:last-child {
                  padding-right: 24px;
                }
              }
            }
          }
        }

        .r2d2-table-body {
          tr {
            td {
              &:last-child {
                padding-right: 24px;
              }
            }

            &:last-child {
              td {
                &:first-child {
                  border-bottom-left-radius: 5px;
                }

                &:last-child {
                  border-bottom-right-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  ##{$table-component}-report_overview_table {
    table {
      .#{$table-component}-body {
        tr {
          td {
            &:first-child {
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }

  ##{$table-component}-lift_percentage {
    @extend .pl-0;
    @extend .pr-0;

    height: $lift-percentage-table-height;
    margin-left: -15px;

    table {
      tbody {
        tr {
          &:first-child {
            td {
              @extend .btw-1;

              @include border-style('solid', 'top');

              border-top-color: $color-gray-300;
            }
          }

          &:last-child {
            td {
              @extend .bbw-1;

              @include border-style('solid', 'bottom');

              border-bottom-color: $color-gray-300;
            }
          }

          td {
            @extend .bbw-1;
            @extend .pb-0;
            @extend .pt-0;

            @include border-style('dashed', 'bottom');

            border-color: $color-gray-300;
            color: $color-black;
            font-weight: bold;
            height: 63px;
          }
        }
      }

      tfoot {
        tr {
          th {
            @extend .text-center;

            border: none;
            color: $color-gray-666;
            padding-top: 0.25em;
          }
        }
      }
    }
  }

  table {
    thead {
      tr {
        th {
          @include border-style('dashed', 'bottom');
          @include border-style('dashed', 'left');
          @include border-style('solid', 'top');

          @extend .bbw-1;
          @extend .blw-1;

          @extend .btw-1;
          @extend .text-right;

          border-color: $color-gray-300;
          padding-bottom: 16px;
          padding-top: 32px;

          &:first-child {
            @include border-style('solid', 'left');

            @extend .blw-2;
            @extend .brw-0;
            @extend .text-left;

            padding-left: 24px;
          }
          &:last-child {
            @extend .brw-2;
          }
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          td {
            &:first-child {
              color: $target-color;
            }
          }
        }

        &:last-child {
          td {
            @include border-style('solid', 'bottom');
            @extend .brw-2;
            @extend .bbw-2;

            &:first-child {
              color: $color-primary;
            }
          }
        }

        td {
          @include border-style('dashed');

          @extend .bbw-2;
          @extend .blw-1;
          @extend .border-light;
          @extend .brw-1;
          @extend .btw-0;
          @extend .pb-1;
          @extend .pt-1;
          @extend .text-right;

          font-weight: $bold-font-weight;

          &:first-child {
            @include border-style('solid', 'left');

            @extend .blw-2;
            @extend .text-left;

            padding-left: 24px;
          }

          &:last-child {
            @include border-style('solid', 'right');

            @extend .brw-2;
          }
        }
      }
    }
  }

  .highcharts-figure,
  .highcharts-data-table table {
    min-width: 320px;
    max-width: 600px;
    margin: 1em auto;
  }

  .highcharts-data-table {
    caption,
    td,
    th {
      padding: 0.5em;
    }

    caption {
      color: #555;
      font-size: 1.2em;
      padding: 1em 0;
    }

    table {
      border-collapse: collapse;
      border: 1px solid #ebebeb;
      font-family: Verdana, sans-serif;
      margin: 10px auto;
      max-width: 500px;
      text-align: center;
      width: 100%;
    }

    th {
      font-weight: 600;
      padding: 0.5em;
    }

    thead tr,
    tr:nth-child(even) {
      background: #f8f8f8;
    }

    tr:hover {
      background: #f1f7ff;
    }
  }
}

.#{$breakdown-component} {
  .#{$tab-panel-component} {
    .#{$dropdown-component} {
      @extend .pl-3;
    }

    .faux-chart-title {
      // match the Highcharts font-family
      color: $highcharts-title-color;
      fill: $highcharts-title-color;
      font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica,
        sans-serif;
      font-size: $highcharts-title-font-size;
      font-weight: bold;
    }

    .legend.delivery-legend {
      @extend .pr-3;
    }

    .pagination-and-sorting-controls {
      .#{$dropdown-component} {
        padding-top: 1.4em;
      }

      .pagination-controls {
        .#{$segmented-control-component} {
          @extend .mx-auto;

          .#{$button-component} {
            max-width: 5em;
          }
        }
      }
    }

    #conversion_table_container {
      display: block;
      max-height: 360px;
      overflow-x: hidden;
      overflow-y: scroll;

      ##{$conversion-table-component} {
        table {
          border-collapse: collapse;

          &.with-target {
            tbody {
              tr {
                &:nth-child(4n) {
                  @extend .bbw-3;
                }
              }
            }
          }

          &.without-target {
            tbody {
              tr {
                &:nth-child(2n) {
                  @extend .bbw-3;
                }
              }
            }
          }

          tbody {
            td {
              overflow: hidden;
              text-overflow: ellipsis;
            }

            tr {
              @extend .bbw-1;
              @extend .border-light;

              @include border-style('solid', 'bottom');

              &:first-child {
                td {
                  padding-top: 20px;
                }
              }

              &:last-child {
                td {
                  padding-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

$visualization-background-color: $color-ispot-gray-3;

$visualization-component-name: 'storytelling-visualization';
$visualization-component: get-component-uuid($visualization-component-name);

.#{$visualization-component} {
  background: $visualization-background-color;

  h2 {
    font-family: $font-primary-400;
  }

  header {
    font-family: $font-primary;
  }
}

//chart and filters wrapper

$chart-filters-wrapper-component-name: 'chart-filters-wrapper';
$filters-background: $color-ispot-gray-2;

$chart-filters-wrapper-component: get-component-uuid(
  $chart-filters-wrapper-component-name
);

$chart-filters-wrapper--padding: 20px;

.#{$chart-filters-wrapper-component} {
  background: $color-white;
  border: 1px solid $color-gray-225;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: $box-shadow;

  &-container {
    padding: $chart-filters-wrapper--padding;
    width: auto;
    &.grid {
      display: grid;
      grid-template-columns: 60% 40%;
    }
  }

  &-header {
    font-family: $font-primary-200;
    font-size: $font-size-XL;
    margin: 0;
  }

  &-filters {
    background: $filters-background;
    padding: $chart-filters-wrapper--padding;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &-label {
    font-family: $font-primary;
    font-size: $font-size-S;
    margin-bottom: 5px;
    margin-top: 0;
    display: block;

    b {
      font-family: $font-primary-600;
    }

    & > span {
      margin-right: 8px;
    }

    &--small-switch {
      margin-right: 9px;
    }
  }

  &-subtitle {
    color: $color-gray-600;
  }

  &-segmented-control-wrapper {
    .#{$flex-component} {
      justify-content: flex-start;
    }

    .#{$button-component} {
      flex: 0 !important;
      padding: 0 16px;
    }
  }

  &-multiselect-dropdown-wrapper {
    width: 250px;
  }

  &-subtitle {
    color: $color-gray-600;
  }
}

$chart-wrapper-component-name: 'chart-wrapper';

$chart-wrapper-component: get-component-uuid($chart-wrapper-component-name);

.#{$chart-wrapper-component} {
  position: relative;

  &__switch-button {
    position: absolute;
    right: 20px;
    bottom: 29px;
  }
}
