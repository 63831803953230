$h3-component-name: 'h3';

$h3-compoment: get-component-uuid($h3-component-name);

.#{$h3-compoment} {
  width: $h3---width;
  color: $h3---color;
  font-family: $h3---font-family;
  font-size: $h3---font-size;
}
