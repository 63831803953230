$list-actions-component-name: 'list-actions';

$list-actions-component: get-component-uuid($list-actions-component-name);
$button-component: get-component-uuid($button-component-name);

.#{$list-actions-component} {
  .#{$button-component} {
    margin-right: 0px;
    margin-left: $list-actions-button---margin-left;

    &.icon {
      width: auto;
    }
  }
}
