$button-dropdown-component-name: 'dropdown-with-headers';
$button-dropdown-component: get-component-uuid($button-dropdown-component-name);

.#{$button-dropdown-component} {
  .btn-group {
    min-width: 120px;
    button {
      background-color: $color-white;
      border: 1px solid #dadada;
      border-radius: 4px;
      margin-bottom: 0.4em;
      margin-top: 0.4em;
      padding: 0.5rem;
      color: $color-black;
      font-weight: 500;
      cursor: pointer;
      position: relative;

      &.dropdown-toggle {
        color: $color-black;
        width: initial;
      }

      &::after {
        position: absolute;
        right: 5px;
        top: 12px;
      }
    }

    .dropdown-menu {
      z-index: 1;
      color: $color-black;
      li {
        font-size: $font-size-M;
        font-weight: 400;
        font-family: $font-primary;
        cursor: pointer;
        &:active {
          color: unset;
          background-color: unset;
        }
      }
      .dropdown-header {
        cursor: default;
      }
    }
  }
}
