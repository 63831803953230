$selected-chips-component-name: 'selected-chips';
$chip-component-name: 'chip';
$flex-component-name: 'flex';

$selected-chips-component: get-component-uuid($selected-chips-component-name);
$chip-component: get-component-uuid($chip-component-name);
$flex-component: get-component-uuid($flex-component-name);

.#{$selected-chips-component} {
  .#{$flex-component} {
    display: flex;
    column-gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    .#{$chip-component} {
      margin-right: $selected-chips---gap;
      margin-bottom: $selected-chips---gap;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.grabbing {
  cursor: grabbing;

  * {
    cursor: grabbing;
  }
}
