$points-controller-component-name: 'points-controller';

$points-controller-component: get-component-uuid(
  $points-controller-component-name
);

.#{$points-controller-component} {
  border: solid 1px $color-gray-225;
  border-radius: 4px;
  align-items: stretch;

  &-filters {
    background: $color-white;
    padding: 12px;
    align-items: stretch;
    height: auto;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
