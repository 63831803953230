$rules-builder-component-name: 'rules-builder';
$rules-builder-item-component-name: 'rules-builder-item';

$rules-builder-component: get-component-uuid($rules-builder-component-name);
$button-component: get-component-uuid($button-component-name);
$flex-component: get-component-uuid($flex-component-name);

.#{$rules-builder-component} {
  &-item {
    margin-bottom: $rules-builder-item---margin-bottom;

    > .#{$flex-component} {
      align-items: baseline;
    }

    &-subitem {
      &.field {
        width: 300px;
      }

      &.operator {
        width: $rules-builder-item---width;
      }

      &.operator,
      &.field {
        margin-right: $rules-builder-item---margin-right;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &.delete {
        padding-top: $rules-builder-sub-item--delete---padding-top;
        width: $rules-builder-sub-item--delete---width;
        margin-left: $rules-builder-item---margin-right;
      }

      &.value {
        flex: 1;

        &:empty {
          flex: none;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-footer {
    .#{$button-component} {
      margin-top: 30px;
    }

    &.no-rules {
      .#{$button-component} {
        margin-top: 5px;
      }
    }
  }

  .rules-builder-header {
    width: 97%;
    margin: auto;
    height: 30px;
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 600;
    }
  }

  .rules-builder-warning {
    width: 315px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 200;
    }
  }
}
