$flex-component-name: 'flex';
$label-component-name: 'label';
$input-wrapper--component-name: 'input-wrapper';

$flex-component: get-component-uuid($flex-component-name);
$label-component: get-component-uuid($label-component-name);
$input-wrapper-component: get-component-uuid($input-wrapper--component-name);

.#{$flex-component} {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  &.between {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.top {
    align-items: flex-start;
  }

  &.baseline {
    align-items: baseline;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.multi {
    flex-wrap: wrap;
  }
}
