$duration-component-name: 'duration-selector';
$duration-item-component-name: 'duration-item';
$duration-wrapper-component-name: 'duration-wrapper';

$duration-component: get-component-uuid($duration-component-name);
$duration-item-component: get-component-uuid($duration-item-component-name);
$duration-wrapper-component: get-component-uuid(
  $duration-wrapper-component-name
);

.#{$duration-wrapper-component} {
  height: 0;
  position: relative;
  z-index: 5;
}

.#{$duration-component} {
  background-color: $color-white;
  border: 1px solid $color-gray-350;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: $font-primary-200;
  padding: 8px;
  width: 215px;
}
.#{$duration-item-component} {
  padding: 0.5em 0;

  & button {
    background: none;
    border: 1px solid $color-gray-500;
    border-radius: 50%;
    color: $color-black;
    font-weight: 900;
    height: 25px;
    margin-left: 4px;
    padding: 0;
    text-align: center;
    width: 25px;

    &:hover {
      background-color: $color-gray-250;
      cursor: pointer;
    }
  }

  & input[type='number'] {
    background: none;
    border: none;
    display: inline;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 50px !important;
  }

  & .name {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 80px;
  }
}
