.crosswalk-combination-form {
  margin-top: 30px;
  .#{$query-builder-item-component} {
    .#{$flex-component} label {
      width: 260px;
      font-weight: bold;
      font-size: 1rem;
      min-height: 90px;
    }

    .#{$input-wrapper-component} {
      margin-top: 0;
    }
  }

  .margin-top-30 {
    margin-top: 30px;
  }
}
