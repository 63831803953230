$date-picker-component-name: 'date-picker';

$date-picker-component: get-component-uuid($date-picker-component-name);
$input-component: get-component-uuid($input-component-name);
$icon-component: get-component-uuid($icon-component-name);

.#{$date-picker-component} {
  cursor: pointer;

  > div {
    line-height: 28px;
  }

  &.disabled .#{$input-component} > div {
    color: $color-text-disabled;
  }

  .hide-presets .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDefinedRangesWrapper {
    &:not(.custom) {
      display: none;
    }
  }

  .rdrDateInput > input {
    background-image: none !important;
  }

  .date:first-of-type {
    margin-right: 20px;
  }

  .date:last-of-type {
    margin-left: 30px;
  }

  .date {
    padding: 6px 16px;
    width: 80px;
    outline: none;
    border: none;
    text-align: center;
    font-family: $font-primary;
    font-size: $font-size-M;

    &.focused {
      background-color: $color-ispot-green-1;
      border-radius: 40px;
      color: white;
    }
  }

  .#{$icon-component} {
    margin-left: 10px;

    &.carrot {
      transform: rotate(90deg);
      right: 0;
      position: absolute;
    }
  }

  .#{$date-picker-component}-wrapper {
    &.floating {
      position: absolute;
      z-index: 1;
      top: 43px;
      left: 15px;
      height: 435px;

      &:not(.hide-presets) {
        width: 765px;
      }

      &.hide-presets {
        width: 664px;
      }

      .calendar-wrapper {
        display: flex;
        flex-direction: row;
        border: $form---border;
        border-radius: 4px;
        background-color: #f0f0f0;
        height: 340px;
        padding: 0;
        .smart-selection-wrapper {
          width: 230px;
          background-color: #f0f0f0;
          .switch-wrapper {
            font-weight: 800;
            display: flex;
            align-items: center;
            margin: auto;
            width: 88%;
            .switch-label {
              padding-top: 8px;
            }
            .switch-info {
              background-color: white;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              left: 5px;
              top: 4px;
              border-radius: 5px;
              border: 1px solid #e0e0e0;
            }
          }
          .smart-selection-container {
            width: 210px;
          }
        }
      }
    }

    &:not(.hide-presets) {
      width: 768px;
    }

    &.hide-presets {
      width: 666px;
    }

    &.anchor-left {
      left: 0;
    }

    &.anchor-right {
      right: 0;
    }
  }

  .broadcast-calendar-footer {
    height: 38px;
    width: 896px;
    position: relative;
    top: 348px;
    background-color: #f0f0f0;
    z-index: 1;
    .footer-wrapper {
      height: inherit;
      display: flex;
      align-items: center;
      margin-left: 20px;
      .footer-box {
        width: 20px;
        height: 20px;
        background-color: white;
        border: 1px solid #bdbdbd;
      }
      span {
        padding-left: 10px;
      }
    }
  }

  .number-of-days {
    width: 240px;
    display: inline-block;
    text-align: right;
    font-style: italic;
    color: $color-text-500;
  }

  .moving-dates-span {
    position: absolute;
    bottom: 5px;
    padding: 5px 10px 0px 5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-color: #dadada;
    margin-left: 10px;
  }

  .moving-dates-txt {
    padding-left: 35px;
  }

  .rdrDateDisplayItemActive {
    background-color: $color-datepicker-range1;
    border-color: $color-datepicker-range1;
    color: white;

    & input {
      color: white;
    }
  }

  .rdrDayPassive {
    visibility: hidden;
  }
}

div[id^='__lpform_input'] {
  visibility: hidden;
}
