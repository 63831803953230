.user-dimension-form {
  margin-top: 30px;
  .#{$query-builder-item-component} {
    .#{$flex-component} label {
      width: 260px;
      font-weight: bold;
      font-size: 1rem;
      min-height: 90px;
    }

    .#{$input-wrapper-component} {
      margin-top: 0;
    }
  }
  .#{get-component-uuid('tabs-tab-panel')} {
    min-height: unset;
  }
  .link-primary {
    text-decoration: #{$button--link---text-decoration} #{$color-primary} !important;
  }
}

.#{$modal-component} {
  section.user-dimension-form-modal {
    min-height: 140px;
    display: flex;
    justify-content: center;
  }
  .user-dimension-form-modal-footer {
    &.#{$flex-component} {
      gap: 1rem;
    }
  }
}
