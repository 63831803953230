$toggle-switch-component-name: 'toggle-switch';

$toggle-switch-bar-component: get-component-uuid($toggle-switch-component-name);

.#{$toggle-switch-bar-component} {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-align: left;
  padding: 0;

  &.small {
    width: 42px;

    .#{$toggle-switch-bar-component} {
      &-inner {
        & > span {
          height: 21px;
          line-height: 22px;
          font-size: 10px;
        }

        .yes-label {
          padding-left: 4px;
        }

        .no-label {
          padding-right: 4px;
        }
      }

      &-switch {
        width: 15px;
        margin: 3px;
        top: 0;
        bottom: 0;
        right: 21px;
      }
    }
  }

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
    padding-right: unset !important;
    width: auto !important;
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    & > span {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: $font-size-M;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    .yes-label {
      content: 'Yes';
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $color-primary-600;
      color: #fff;
    }

    .no-label {
      content: 'No';
      text-transform: uppercase;
      padding-right: 10px;
      background-color: #727e8d;
      color: #fff;
      text-align: right;
    }
  }

  &-inner.disabled {
    & > span {
      cursor: not-allowed;
    }
  }

  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked + .#{$toggle-switch-bar-component}-label {
    .#{$toggle-switch-bar-component}-inner {
      margin-left: 0;
    }

    .#{$toggle-switch-bar-component}-switch {
      right: 0;
    }
  }

  &-checkbox.disabled:checked + .#{$toggle-switch-bar-component}-label {
    .#{$toggle-switch-bar-component}-inner {
      cursor: not-allowed;
    }

    .#{$toggle-switch-bar-component}-switch {
      cursor: not-allowed;
    }
  }
}
