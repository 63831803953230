$input-component-name: 'input';
$input-component: get-component-uuid($input-component-name);
$button-component-name: 'button';
$button-component: get-component-uuid($button-component-name);

.auth0-lock-header {
  display: none;
}

.auth0-lock.auth0-lock .auth0-lock-form p {
  font-family: $font-primary !important;
  font-size: $font-size-XM !important;
  text-align: center !important;
  color: $color-gray-800 !important;
}

.auth0-lock-input-wrap > span {
  display: none;
}

.auth0-lock-input:focus {
  outline-color: $form--focus---outline-color !important;
}

.r2d2-login-form
  .auth0-lock.auth0-lock
  .auth0-lock-input-wrap
  .auth0-lock-input {
  @extend .#{$input-component};
}

.r2d2-login-form .auth0-lock.auth0-lock .auth0-lock-submit {
  @extend .#{$button-component};
  @extend .#{$button-component}, .large;
  @extend .#{$button-component}, .primary;

  background-color: $button--primary---background-color !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap {
  border: none !important;
  background: $color-white !important;
  padding-left: 0 !important;
}

.auth0-lock.auth0-lock .auth0-lock-widget {
  width: 100% !important;
}

.auth0-lock-badge-bottom {
  display: none !important;
}

.auth0-lock-input-block {
  width: auto !important;
  margin-bottom: 8px !important;
}

.auth0-lock-content {
  padding: 0 !important;
}

.auth0-global-message-error {
  box-sizing: border-box !important;
  width: 300px;
}

.auth0-lock-submit > div::after {
  content: 'Change password';
}

.auth0-lock-submit span svg {
  display: none;
}

.auth0-lock input {
  margin: auto !important;
  display: block !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $color-white inset !important;
}
