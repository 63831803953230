$popover-menu-content-component-name: 'popover-menu-content';

$popover-menu-content-component: get-component-uuid(
  $popover-menu-content-component-name
);
$button-component: get-component-uuid('button');

.#{$popover-menu-content-component} {
  font-size: $popover-menu-content---font-size;
  color: $popover-menu-content---color;
  font-family: $popover-menu-content---font-family;

  ul {
    margin: 0;
    padding: 0;
    padding-top: $popover-menu-content--list---margin;
    padding-bottom: $popover-menu-content--list---margin;

    li {
      cursor: pointer;
      list-style-type: none;
      color: $popover-menu-content--list-item---color;

      .#{$button-component} {
        &.link,
        &.text {
          font-family: $popover-menu-content---font-family;
          font-size: $popover-menu-content---font-size;
          padding: $popover-menu-content--list-item---padding;
          text-align: left;
          text-transform: none;
          width: 100%;
        }

        &.link {
          text-decoration: none;
        }
      }

      .#{$prefix-components}-icon {
        margin-right: 10px;
      }
    }

    a {
      color: $popover-menu-content--list-item---color;
    }

    a,
    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
    }

    li:hover {
      background-color: $popover-menu-content--list-item--hover---background-color;
      color: $popover-menu-content--list-item--hover---color;

      .#{$prefix-components}-icon {
        filter: $icon--light---filter;
      }

      a {
        color: $popover-menu-content--list-item--hover---color;
      }

      .#{$button-component} {
        &.link,
        &.text {
          color: $popover-menu-content--list-item--hover---color;
        }
      }
    }
  }
}
