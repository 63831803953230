$modal-component-name: 'modal';

$modal-component: get-component-uuid($modal-component-name);

.ReactModal__Overlay {
  opacity: 0;
  transition: $modal--overlay---transition;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.#{$modal-component}-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $modal--overlay---background-color;
  z-index: $modal---z-index;
  transition: $modal--overlay---transition;
}

.#{$modal-component} {
  box-shadow: $modal---box-shadow;
  border-radius: $modal---border-radius;
  background: $modal---background;
  position: absolute;
  min-width: $modal--dialog---min-width;

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-container {
    height: 100%;
  }

  &:focus {
    outline: none;
  }

  &.dialog {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: $modal--dialog---height;
    padding: $modal--dialog---padding;
    box-sizing: border-box;

    header,
    section,
    footer {
      padding: $modal--header---padding;
    }
  }

  &.full-screen {
    top: $modal---distance-from-edge;
    left: $modal---distance-from-edge;
    right: $modal---distance-from-edge;
    bottom: $modal---distance-from-edge;
  }

  &-container > * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  header {
    padding: $modal--header---padding;
    box-sizing: border-box;
    width: 100%;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $font-size-XXM;
      margin: 0;
    }
  }

  section {
    font-size: $modal---font-size;
    font-family: $modal---font-family;
    padding: $modal--body---padding;
    overflow: revert;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
  }

  footer {
    padding: $modal--footer---padding;
    width: 100%;
    box-sizing: border-box;
  }

  .separators {
    header {
      border-bottom: $modal---separator;
    }

    footer {
      border-top: $modal---separator;
    }
  }
}
