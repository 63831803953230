.inline-alert {
  font-family: $font-primary;
  font-weight: $font-weight-XS;
  font-size: $font-size-S;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;

  &.warn {
    border: 1px solid $color-orange;
  }

  &.validate {
    border: 1px solid red;
    width: fit-content;
    background: white;
    margin-right: auto;
    margin-left: auto;
  }

  &.omission {
    border: solid 1px $color-gray-3;
    font-size: $font-size-M;
    padding: 12px 15px;

    .message {
      padding: 0 20px 0 9px;
    }
  }

  &.white {
    background-color: white;
  }

  .message {
    padding: 0 5px;
    span.red {
      color: red;
    }
  }
}
