$skeleton-button-component-name: 'skeleton-button';

$skeleton-button-component: get-component-uuid($skeleton-button-component-name);

.#{$skeleton-button-component} {
  height: 45px;
  width: $button--medium---width;
  background: $skeleton---background;
  background-size: $skeleton---background-size;
  animation: $skeleton-animation;
}
