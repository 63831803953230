// Used by Auth0 templates: login.template.html and changePassword.template.html
// Any changes to this file cannot be tested locally.
$footer-component-name: 'footer';

$footer-component: get-component-uuid($footer-component-name);

.#{$footer-component} {
  display: block;
  width: 100%;
  background: $footer---no-background;
  height: $footer---height;
  position: sticky;
  bottom: 0;

  &.disclaimer {
    height: $footer--disclaimer---height;
  }

  &.background {
    background: $footer---background;
  }

  &-disclaimer {
    display: none;
    font-size: $footer-disclaimer---font-size;
    font-family: $footer-disclaimer---font-family;
    text-align: $footer-disclaimer---text-align;
    color: $footer-disclaimer---color;
    margin-bottom: $footer-disclaimer---margin-bottom;

    + .#{$footer-component}-rights {
      margin-top: $footer-rights---margin-top;
    }
  }

  &.disclaimer .#{$footer-component}-disclaimer {
    display: block;
  }

  &.disclaimer a {
    font-family: $footer-link---font-family;
    font-size: $footer-link---font-size;
    color: $footer-link---color;
  }

  &-rights {
    font-family: $footer-rights---font-family;
    font-size: $footer-rights---font-size;
    color: $footer-rights---color;
    margin: 0;
  }
}
